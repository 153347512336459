import { Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import { register, registerFederated } from './redux/actions/user';
import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT } from './redux/actions/types';
import i18n from './i18n';
import namedBaselines from './misc/namedBaselines';

const debugLevel = process.env.REACT_APP_MDEBUG || 0;

// take whatever currentAuthenticatedUser returns and update the store with it
export function updateStoreWithAttributes(user, store) {
  if (debugLevel > 2) console.info('Hub Listener: updateStoreWithAttributes(user)', user);
  // prepare data
  const defaultBaselineDate = {
    name: 'sameTimeLastYear',
    date: namedBaselines.sameTimeLastYear,
  };
  const settingsString = user.attributes['custom:settings'];
  const settings = settingsString
    ? JSON.parse(settingsString)
    : {
      baseCurrency: 'EUR',
      baselineDate: defaultBaselineDate.date,
      namedBaseline: defaultBaselineDate.name,
      inflationRate: 3.0,
      sliderStartDate: dayjs().subtract(10, 'year').startOf('year').valueOf(),
      sliderEndDate: dayjs().add(20, 'year').startOf('year').valueOf(),
      expectedDateFormats: ['DD.MM.YYYY', 'YYYY-MM-DD', 'DD.MM.YY', 'DD/MM/YYYY', 'DD-MM-YYYY'], // default setting
    };

  const locale = user.attributes.locale || null;
  if (locale !== i18n.language && locale?.length === 2) {
    i18n.changeLanguage(locale);
  }

  // needed for persist.js
  const { sub } = user.attributes;

  store.dispatch({
    type: LOGIN_SUCCESS,
    // just send settings and toursseen, the rest is not needed
    // INFO: toursSeen are used in the Prep component to determine whether to create demo data or not (if there are no toursSeen, then it will create demo data)
    payload: {
      toursSeen: JSON.parse(user.attributes['custom:tours_seen'] || 'null'),
      settings,
      locale,
      sub,
    },
  });
}

export default function listener(capsule, store) {
  // Listening for all messages
  if (debugLevel > 2) console.info('Amplify hub event', capsule);

  // INFO: (apparently) Amplify "recognises" a callback by specific query parameters in the URL (e.g. setup_intent, id_token, code, state).
  // INFO: Amplify emits a parsingCallbackUrl on its 'auth' channel when the website is loaded, and passes the URL as payload.data.url
  // INFO: the functions here are executed before CallbackHandler had a chance to run

  // handle application start ---------------------------------------------------------------------------------------------------

  if (capsule.payload.event === 'configured') {
    // check if user is signed in
    // currentSession should have the most current attributes after signup already
    Auth.currentSession().then(
      (currentSession) => {
        // need to pass something that has user.attributes
        const user = { attributes: currentSession.idToken.payload }; // currentSession object is different to what currentAuthenticatedUser returns
        updateStoreWithAttributes(user, store);
      },
      (err) => {
        if (err !== 'No current user') console.error("Hub Listener: 'configured' event handler: error", err);
        // dispatch action
        store.dispatch({
          type: LOGIN_FAIL,
          payload: err,
        });
      },
    );
  }

  // handle token refresh failure -----------------------------------------------------------------------------------------------

  if (capsule.payload.event === 'tokenRefresh_failure') {
    store.dispatch({
      type: 'SET_APP_SIGNAL',
      payload: { callerId: 'RequireAuth', message: 'tokenRefresh_failure' },
    });
  }

  // handle signIN --------------------------------------------------------------------------------------------------------------

  if (capsule.payload.event === 'signIn') {
    // token will be checked by RequireAuth when App is mounted
    // CAUTION: this handler does not run when the user arrives from the mobile app → change MobileLogin component if there are logic changes here
    console.log('Hub Listener: signIn handler started');
    const response = Auth.currentAuthenticatedUser().then(
      (user) => {
        // working hypothesis: since this is done upon login, the user attributes should be up-to-date and currentSession should not be needed
        updateStoreWithAttributes(user, store);
        // this ↓↓ is a failsafe in case the registration process somehow did not managed to remove it; if a login is successful, this object is no longer needed
        // if this object is there, registerFederated will think we are creating a user instead of signing them up
        localStorage.removeItem('signUpObject');
      },
      (err) => {
        console.error('Hub Listener: signIn handler: error', err);
        // dispatch action
        store.dispatch({
          type: LOGIN_FAIL,
          payload: err,
        });
      },
    );
  }

  // handle FEDERATED signUP ---------------------------------------------------------------------------------------------------

  if (capsule.payload.event === 'cognitoHostedUI_failure') {
    // process details: https://monestry.atlassian.net/wiki/spaces/TECH/pages/240222776/User+registration+payment+process

    if (debugLevel > 2) console.info('HubListener: cognitoHostedUI_failure handler started for:', capsule.payload.data.message); // payload.data.message is the Error object

    const remoteErrorMessage = capsule.payload.data?.message;

    // Stage I: get email from error message and dispatch it to redux store
    const expectedErrorString = 'PreSignUp+failed+with+error+Error+while+getting+Cognito+users%3A+EMAIL%3A';

    if (remoteErrorMessage?.includes(expectedErrorString)) {
      const email = remoteErrorMessage.split(expectedErrorString)[1].slice(0, -2).replace('%40', '@');
      if (debugLevel > 2) console.info('email from error message', email, remoteErrorMessage);

      // handle case when user has a native Cognito account, but tries to use a federated identity with a different account
      // federatedSignIn will fail, but we also need to discontinue the choreographed process triggered here
      // if there is no signUpObject, it means we are not in the registration process and the error is relevant
      const formObject = JSON.parse(localStorage.getItem('signUpObject'));
      if (!formObject) {
        if (debugLevel > 2) console.error('HubListener: cognitoHostedUI_failure handler: no signUpObject, redirecting back to Login');
        window.location.replace('/login?unknownUser=true');
      } else {
        // registerFederated returns a promise
        const result = store.dispatch(registerFederated(email)).then(
          (data) => {
            console.log('HubListener: result of registerFederated', data);
          },
          (err) => {
            console.warn('HubListener: caught error while running registerFederated', err);
            // redirect to fallback handler
            window.location.replace(`/fallback?fallbackMessage=${remoteErrorMessage}`);
          },
        );
      }
    }

    // Stage II: external account has been merged into the new internal account, redirect to PaymentSuccessful
    const expectedErrorStringMerge = 'PreSignUp+failed+with+error+Error+while+linking+external+user+to+Cognito+user%3A+USERMERGED%3A';

    // 240920 DEPRECATED - no longer needed for the flow - user should just proceed to login
    // if (remoteErrorMessage?.includes(expectedErrorStringMerge)) {
    //   if (debugLevel > 2) console.info('HubListener: cognitoHostedUI_failure handler: USERMERGED detected, redirecting to PaymentSuccessful');

    //   // add 'userState' = 'confirmed' to the session storage
    //   const formObject = JSON.parse(localStorage.getItem('signUpObject')); // This only works for the first-time created user (not for creating a federated user AFTER the native user)
    //   if (formObject) {
    //     // if we have the formObject, we are in the registration process
    //     formObject.userState = 'confirmed';
    //     localStorage.setItem('signUpObject', JSON.stringify(formObject));
    //     // redirect to PaymentSuccessful
    //     window.location.replace(`/confirm/${formObject.username}`);
    //   } else {
    //     // TODO is the user signed in in this case?
    //   }
    // }

    // seen errors:
    // PreSignUp+failed+with+error+Error+while+updating+Cognito+user+attributes%3A+Email+is+required+to+verify%2Fun-verify+an+email.+

    // catch and handle unexpected error
    if (!remoteErrorMessage?.includes(expectedErrorString) && !remoteErrorMessage?.includes(expectedErrorStringMerge)) {
      console.error('HubListener: cognitoHostedUI_failure handler: unexpected error', remoteErrorMessage);
      // redirect to fallback handler
      window.location.replace(`/fallback?fallbackMessage=${remoteErrorMessage}`);
    }
  }

  // handle signOUT -------------------------------------------------------------------------------------------------------------
  if (capsule.payload.event === 'signOut') {
    // does it have to happen in PerformLogout AND here?
    console.log('DEBUG initiating logout from hubListener');
    store.dispatch({
      type: LOGOUT,
    });
  }

  // handle callback from STRIPE setup intent --------------------------------------------------------------------------------------------

  if (capsule.payload.data?.url?.includes('setup_intent') || capsule.payload.data?.url?.includes('pay_later')) {
    // parse the URL
    const { url } = capsule.payload.data;
    const parsedUrl = new URL(url);
    const setupIntentId = parsedUrl.searchParams.get('setup_intent');
    const setupIntentClientSecret = parsedUrl.searchParams.get('setup_intent_client_secret');
    const payLater = parsedUrl.searchParams.get('pay_later');

    // if pay_later exists, the user wants to pay later, so no secret has been provided
    if (!payLater) {
      // store the payment intent in session storage
      sessionStorage.setItem('setupIntent', JSON.stringify({ setupIntentId, setupIntentClientSecret }));
      if (debugLevel > 2) console.info('HubListener: setupIntent stored in session storage');
    } else {
      // store the payLater information in session storage
      if (debugLevel > 2) console.info('HubListener: pay_later mode detected');
      sessionStorage.setItem('payLater', JSON.stringify('true'));
    }

    // kick off the Cognito registration flow
    console.info('HubListener: initiating Cognito registration flow');

    // get the user object from sessionStorage (it was saved there by SignUp3)
    const user = JSON.parse(localStorage.getItem('signUpObject'));

    // this will either create a regular user or kick off the federated user process
    try {
      store.dispatch(register(user));
    } catch (err) {
      console.info('HubListener: detected error while running register');
      window.location.replace(`/fallback?fallbackMessage=${err}`);
    }

    // capture return from store in the CallbackHandler component, which is anyway open
  }
}
