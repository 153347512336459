const assetsBalanceChanges = {
  cols: ['accountName'],
  dataScope: 'snapshots',
  rows: ['Date (Year)'],
  rendererName: 'Area chart',
  id: 'assetBalancesOverTime',
  name: 'Asset balance changes',
  readOnly: true,
  aggregatorName: 'Sum',
  vals: ['value'],
};

export default assetsBalanceChanges;
