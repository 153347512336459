import * as common from './params/common';
import gridCustomDropdown from './gridCustomDropdown';

// this function attempts to automatically create spreadsheet (formatting) masks based on
// the output of toLocaleString for a given locale / currency parameter
// this may not work for exotic formats, has been confirmed to work with EN and DE only
// locale should match the country, not the language
function generateSpreadsheetMaskFromLocale(locale = 'de', currency = 'EUR', returnMode = 'currency') {
  if (process.env.REACT_APP_MDEBUG > 2) console.log('started generateSpreadsheetMaskFromLocale', locale, currency, returnMode);
  // other returnModes: 'decimalPoint' || 'number2Decimals' || 'number0Decimals' || 'number4Decimals'

  const testValue = 1234.567;
  const testString = testValue.toLocaleString(locale, { style: 'currency', currency });
  // if there is anything before first number, this is the currency
  // just put all that on the begining of the mask
  // include the space -- examples: kr 1 234,57 €1,234.57

  const firstInt = testString.search(/\d/);
  let maskPrefix = '';
  let maskSuffix = '';

  // if the first sign is a number, just get all the non-numbers from the end
  // of the string and assume this is the currency code
  // include the space
  if (firstInt !== 0) maskPrefix = testString.substring(0, firstInt);
  else maskSuffix = testString.substring(testString.search(/(\d)(?!.*\d)/) + 1);

  // if there is a space or character between 1 and 2, this is the thousands separator
  // can be a space
  const thousandSeparator = testString.substring(testString.indexOf('1') + 1, testString.indexOf('1') + 2);

  // there can only be a comma or a point between 4 and 5
  const decimalSeparator = testString.substring(testString.indexOf('4') + 1, testString.indexOf('4') + 2);

  // let's put it all together
  const mask = `${maskPrefix}#${thousandSeparator}##0${decimalSeparator}00${maskSuffix}`;
  const maskNumber = `#${thousandSeparator}##0${decimalSeparator}`;

  if (returnMode === 'decimalPoint') return decimalSeparator;
  if (returnMode === 'number0Decimals') return maskNumber.slice(0, -1);
  if (returnMode === 'number2Decimals') return `${maskNumber}00`;
  if (returnMode === 'number4Decimals') return `${maskNumber}0000`;
  if (returnMode === 'currency0Decimals') return `${maskPrefix}#${thousandSeparator}##0${maskSuffix}`;
  return mask;
}

// -----------------------------------------------------------------------
// COLUMN FORMATTER (based on gridLayout)
// -----------------------------------------------------------------------

// expects an array of column definitions from gridLayout
// updates each object in the array with type-specific properties needed for Grid
// we can't pass customDropdownSources via options (options.* get stripped down to only the known ones), so we need to use columns.*
export default function addFormattingToColumns(gridLayout, customDropdownSources, tableState, baseCurrency, i18n, t, secondaryCurrency = null) {
  if (!gridLayout) return null; // for import > Previewer (user sees input file), we don't have any columns data
  return gridLayout.map((col) => {
    // if usesHeader is defined, use it, otherwise use the default column id
    const colLabel = col.usesHeader ?? col.id;
    // add standard properties to each column
    const standardProperties = {
      title: `${t([`columns.${colLabel}.label`, `columns.${colLabel}`])}${col.readOnly ? `\n${t('columns.readOnly')}` : ''}`,
      name: col.id, // required by Grid to convert data from array of objects to array of arrays
      // conditionally insert tooltip
      ...(i18n.exists(`app:accountDetails.columns.${colLabel}.tooltip`) && { tooltip: t(`columns.${colLabel}.tooltip`) }),
      width: (col.width || tableState.colWidths?.[col.id]) ?? 100,
      // condditionally add read-only
      ...(col.readOnly && { readOnly: true }),
    };

    // add specific properties to each column by its .type
    switch (col.type) {
      case 'date':
        return {
          ...standardProperties,
          ...common.calendarColumnAttributes,
        };
      case 'string':
        return {
          ...standardProperties,
          type: 'text',
          wordWrap: true,
          wrap: true,
          align: 'left',
        };
      case 'currency0Decimals':
        return {
          ...standardProperties,
          type: 'number',
          align: 'right',
          mask: generateSpreadsheetMaskFromLocale(i18n.language, baseCurrency, 'currency0Decimals'),
          decimal: generateSpreadsheetMaskFromLocale(i18n.language, baseCurrency, 'decimalPoint'),
        };
      case 'currency':
        return {
          ...standardProperties,
          type: 'number',
          align: 'right',
          mask: generateSpreadsheetMaskFromLocale(i18n.language, baseCurrency, 'currency'),
          decimal: generateSpreadsheetMaskFromLocale(i18n.language, baseCurrency, 'decimalPoint'),
        };
      case 'secondaryCurrency0Decimals':
        return {
          ...standardProperties,
          type: 'number',
          align: 'right',
          mask: generateSpreadsheetMaskFromLocale(i18n.language, secondaryCurrency, 'currency0Decimals'),
          decimal: generateSpreadsheetMaskFromLocale(i18n.language, secondaryCurrency, 'decimalPoint'),
        };
      case 'secondaryCurrency':
        return {
          ...standardProperties,
          // type: 'number',
          // type: 'text',
          type: 'numeric',
          align: 'right',
          mask: generateSpreadsheetMaskFromLocale(i18n.language, secondaryCurrency, 'currency'),
          decimal: generateSpreadsheetMaskFromLocale(i18n.language, secondaryCurrency, 'decimalPoint'),
        };
      case 'dropdown':
        return {
          ...standardProperties,
          type: 'dropdown',
          align: 'center',
          source: col.source,
          autocomplete: true,
        };
      // same as 'dropdowm', but we add source when we render Grid and not when we start the app
      case 'dropdownDynamic': {
        const source = customDropdownSources[col.id];
        if (!source || source.length === 0) console.warn('Grid > addFormattingToColumns: no source found for dynamic dropdown column', col.id, 'in customDropdownSources', customDropdownSources);
        return {
          ...standardProperties,
          type: 'dropdown',
          align: 'center',
          source,
          autocomplete: true,
        };
      }
      case 'customDropdown': {
        // get the right source list from customDropdownSources
        const source = customDropdownSources[col.id];
        if (!source || source.length === 0) console.warn('Grid > addFormattingToColumns: no source found for custom dropdown column', col.id, 'in customDropdownSources', customDropdownSources);
        return {
          ...standardProperties,
          type: 'text',
          align: 'left',
          editor: gridCustomDropdown,
          source,
        };
      }
      case 'number0Decimals':
        return {
          ...standardProperties,
          type: 'number',
          align: 'right',
          mask: generateSpreadsheetMaskFromLocale(i18n.language, baseCurrency, 'number0Decimals'),
          decimal: generateSpreadsheetMaskFromLocale(i18n.language, baseCurrency, 'decimalPoint'),
        };
      case 'number':
        return {
          ...standardProperties,
          type: 'number',
          align: 'right',
          mask: generateSpreadsheetMaskFromLocale(i18n.language, baseCurrency, 'number2Decimals'),
          decimal: generateSpreadsheetMaskFromLocale(i18n.language, baseCurrency, 'decimalPoint'),
        };
      case 'number4Decimals':
        return {
          ...standardProperties,
          type: 'number',
          align: 'right',
          mask: generateSpreadsheetMaskFromLocale(i18n.language, baseCurrency, 'number4Decimals'),
          decimal: generateSpreadsheetMaskFromLocale(i18n.language, baseCurrency, 'decimalPoint'),
        };
      case 'checkbox':
        return {
          ...standardProperties,
          type: 'checkbox',
          align: 'center',
        };
      case 'hidden':
      default:
        if (col.type !== 'hidden') console.warn('Grid > addFormattingToColumns: unknown column type', col.type);
        return { ...standardProperties, type: 'hidden' };
    }
  });
}
