/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DndContext, DragOverlay } from '@dnd-kit/core';
import { XMarkIcon, CheckIcon, DocumentArrowDownIcon, ForwardIcon, ViewColumnsIcon, PencilIcon } from '@heroicons/react/24/outline';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { InformationCircleIcon, QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import { getSchemaByCategory, quote, quotes, customQuoteTypesPension } from '@monestry-dev/schema';
import ColumnMatcherErrorPopover from './ColumnMatcherErrorPopover';
import applyCommands from '../gridApplyTransformations';
import { putAccount } from '../../../redux/reducers/data';
import { Reset } from '../../../assets/mdiIcons';
import ToolTipNoIcon from '../../../elements/ToolTipNoIcon';
import { setMessage } from '../../../redux/actions/message';
import DataModelColumn from './DataModelColumn';
import FileColumn from './FileColumn';
import FilePreview from './FilePreview';
import Button from '../../../elements/Button';
import ColumnMatcherRVUebersicht from './ColumnMatcherRVUebersicht';
import ColumnMatcherAddQBCToPension from './ColumnMatcherAddQBCToPension';

dayjs.extend(utc);
dayjs.extend(customParseFormat);

const debugLevel = process.env.REACT_APP_MDEBUG || 0;

const colours = ['bg-brandGreen-500', 'bg-brandBlue-500', 'bg-brandRed-500', 'bg-brandDarkBlue-500', 'bg-brandViolet-500', 'bg-brandYellow-500'];

export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function ColumnMatcherInner({
  currentFileIngested, // FIXME implement ingested->pretransformed transformation
  currentFileName,
  updateCurrentFileTransformed,
  currentFilePretransformed,
  updateCurrentFilePretransformed,
  transformationParams,
  setTransformationParams,
  showSkipFileButton,
  skipToNextFile,
  setDisplayedComponent,
  displayedComponentMode,
  alert,
  setAlert,
  account,
  pretransformationApplied,
  setPretransformationApplied,
  setScrollToRow, // passed to ColumnMatcherErrorPopover so that the user can go to Grid from there
}) {
  if (debugLevel > 2) console.log('ColumnMatcherInner', currentFilePretransformed);
  // the draggable FileColumn components are either shown in the "pool" on the bottom,
  // or at their target spots among the columns on top
  // dropState has key-value pairs which tell us which FileColumn objects are located in the columns
  // if the FileColumn id is not in the dropState object, it is not dropped anywhere and should be shown at the bottom
  const dispatch = useDispatch();
  const { t } = useTranslation(['app'], { keyPrefix: 'accountDetails.buttons' });

  let arraySchema;
  let objectSchema;

  if (displayedComponentMode === 'transactions') {
    arraySchema = getSchemaByCategory(account.category, 'transaction', true);
    objectSchema = getSchemaByCategory(account.category, 'transaction', false);
  } else if (displayedComponentMode === 'quotes') {
    arraySchema = quotes;
    objectSchema = quote;
  }

  if (!currentFileIngested || currentFileIngested.length === 0) return true;

  // -----------------------------------
  // HELPER FUNCTIONS
  // -----------------------------------

  // returns the index of the column where the headers should be based on the file input data
  // only runs once during initialisation
  function detectHeaders(parsedArray, fileColumnStats, _expectedDateFormats) {
    // get the maximum number of columns in the file as column count
    // try to detect headers: try to find a row with the same amount of columns as the last row and all strings
    let j = 0;
    let suggestedHeaderRow; // undefined
    while (j < parsedArray.length && suggestedHeaderRow === undefined) {
      // if it is not true, that the row has at least one number or one date
      if (parsedArray[j].length === fileColumnStats.maxCols && !parsedArray[j].some((item) => /[0-9]*[,.][0-9]*$/.test(item) || dayjs(item, _expectedDateFormats, true).isValid())) suggestedHeaderRow = j;
      j += 1;
    }
    return suggestedHeaderRow;
  }

  const decimalRef = useRef(null); // used to store the decimal symbol in the state

  function convertStringToNumber(inputString, entireColumn = undefined) {
    if (debugLevel > 2) console.log('DEBUG convertStringToNumber', inputString, entireColumn);
    try {
      // if entire column has been passed and if the decimal symbol has not been set locally, try to detect it and set in state
      if (entireColumn && !decimalRef.current) {
        // if entireColumn is set, check the entire column for the most common non-digit character
        const nonDigits = entireColumn.map((item) => item.replace(/[^.,]/g, '').trim()); // replace everything that isn't a dot or comma
        const decimalsObject = nonDigits.reduce((acc, cur) => {
          const decimal = cur.slice(-1);
          if (decimal) {
            if (!acc[decimal]) acc[decimal] = 1;
            else acc[decimal] += 1;
          }
          return acc;
        }, {});
        const decimal = Object.keys(decimalsObject).reduce((a, b) => (decimalsObject[a] > decimalsObject[b] ? a : b), '.');
        decimalRef.current = decimal;
        // fix the current inputString (leaving it duplicated in case decimal symbol is delayed in state)
        const theOtherSymbol = decimalRef.current === '.' ? ',' : '.';
        const num = Number(
          inputString
            .replace(/[^\d-.,]/g, '')
            .trim()
            .replace(theOtherSymbol, '')
            .replace(decimalRef.current, '.'),
        );
        return num;
      }
      if (decimalRef.current) {
        // if the decimal symbol has been set, use it to convert the string to a number
        const theOtherSymbol = decimalRef.current === '.' ? ',' : '.';
        const num = Number(
          inputString
            .replace(/[^\d-.,]/g, '')
            .trim()
            .replace(theOtherSymbol, '')
            .replace(decimalRef.current, '.'),
        );
        return num;
      }

      // if we are somehow here, try it the old way
      if (debugLevel > 2) console.info('ColumnMatcher.jsx: convertStringToNumber: we are trying the old way, which should not have happened');

      let num = Number(inputString.replace(/[^0-9.,-]/g, '').trim());

      // Check if the initial conversion is successful
      if (!Number.isNaN(num)) {
        return num;
      }

      // Replace dots with nothing and commas with dots
      const modifiedString = inputString.replace(/\./g, '').replace(/,/g, '.');

      // Try to convert the modified string to a number
      num = Number(modifiedString);
      if (!Number.isNaN(num)) {
        return num;
      }
    } catch (error) {
      if (debugLevel > 2) console.error('ColumnMatcher.jsx: convertStringToNumber: error', error);
    }
    // if everything fails, return null
    return null;
  }

  // converts the current file (in state) into an object with headers and rows
  // returns fileObject { headers: [], rows: [] }
  function transformInputObject(inputArray, suggestedHeaderRow, fileColumnStats) {
    // input is an array of arrays, each array is a row
    // prepare output: { headers: { id ('column1'), label ('name from file or default'),
    // description (default "column 1 of your file"), bgColour }, rows: [[val1, val2, ... valN], [val1, val2, ... valN],...]}
    const output = {
      headers: [],
      rows: [],
    };
    // if there is a header row, use it to create the headers; if there is no header row, create default headers based on the detected column numbers
    const headerArray = inputArray[suggestedHeaderRow === null || suggestedHeaderRow === undefined ? fileColumnStats.maxColsRow : suggestedHeaderRow];
    const longestRowLength = fileColumnStats.maxCols;
    for (let i = 0; i < headerArray.length; i += 1) {
      output.headers.push({
        id: `column${i + 1}`,
        label: suggestedHeaderRow === undefined ? `column${i + 1}` : headerArray[i] || 'empty',
        description: `Column ${i + 1} of your file`,
        bgColour: colours[i % colours.length],
      });
    }
    // if there is a header row, start at the next row, otherwise start at the first row
    for (let i = suggestedHeaderRow === undefined ? 0 : suggestedHeaderRow + 1; i < inputArray.length; i += 1) {
      if (inputArray[i].length < longestRowLength) {
        // if the row is shorter than the longest row, fill it up with nulls
        const updatedRow = new Array(longestRowLength - inputArray[i].length).fill(null);
        output.rows.push(inputArray[i].concat(updatedRow));
      } else output.rows.push(inputArray[i]);
    }
    return output;
  }

  // -----------------------------------
  // SELECTORS + STATE
  // -----------------------------------

  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency);
  const selectDateFormats = useSelector((state) => state.user.profile.settings.expectedDateFormats); // contains a parsed array of formats, user can change defaults in Settings > Preferences
  const expectedDateFormats = [...selectDateFormats]; // copy the array to avoid mutation

  // finds a row with the most number of columns and stores its index as maxColsRow and the number of columns as maxCols
  const [fileColumnStats, setFileColumnStats] = useState(() => {
    const initialState = currentFilePretransformed.reduce((acc, cur, idx) => (cur.length > acc.maxCols ? { maxCols: cur.length, maxColsRow: idx } : acc), { maxCols: 0, maxColsRow: 0 });
    return initialState;
  });

  const [currentFileObject, setCurrentFileObject] = useState({ headers: [], rows: [] }); // changes are handled in useEffect to handle custom columns
  const [activeFileColumn, setActiveFileColumn] = useState(null); // handles appearance of the DragOverlay component
  const [allFieldsAssigned, setAllFieldsAssigned] = useState(false); // true if all mandatory fields are assigned (there can be validation errors)
  const [formReadyToSubmit, setFormReadyToSubmit] = useState(false); // true if all mandatory fields are filled out and there are no validation errors

  // contains mappings, initialise empty; fill out with values from the mapping in redux / in category service in useEffect, once custom columns are added
  const [dropState, setDropState] = useState(transformationParams.mappings || {});
  // in which columns are filecolumns by id: { fileCol2: 'col2', fileCol3: 'col4', fileCol5: null ...}
  // should be: { dataModelCol1: fileCol1, dataModelCol2: fileCol2, ...}

  const [previewState, setPreviewState] = useState(null); // used to show the preview of the file column in the column
  const [validationErrors, setValidationErrors] = useState([]); // contains errors from the validation of the form (after each drop)
  const [loading, setLoading] = useState(false); // loading state for the submit button
  const [useOnlyThisRow, setUseOnlyThisRow] = useState(undefined); // for pensions users may want to import their RV app file which contains data for several accounts (not just this one)

  const componentRef = useRef();

  // ------------------------
  // HANDLE RV APP IMPORT
  // ------------------------

  function callback(row) {
    setUseOnlyThisRow(row);

    // the file columns will be numbered through (column01, column02, ... column99), so - in case the number of columns in the file has changed - we need to find them by name and get their index
    setDropState({
      date: `column${(currentFilePretransformed[0].findIndex((col) => col === 'Stichtag Wertangaben') + 1).toString().padStart(2, '0')}`,
      payoutOnlyContributionsToDate: `column${(currentFilePretransformed[0].findIndex((col) => col === 'Garantiert erreichte Rente') + 1).toString().padStart(2, '0')}`,
      payoutOnlyContributionsToDateIndexed: `column${(currentFilePretransformed[0].findIndex((col) => col === 'Prognostiziert erreichte Rente') + 1).toString().padStart(2, '0')}`,
      payoutAllPlannedContributions: `column${(currentFilePretransformed[0].findIndex((col) => col === 'Garantiert erreichbare Rente') + 1).toString().padStart(2, '0')}`,
      payoutAllPlannedContributionsIndexed: `column${(currentFilePretransformed[0].findIndex((col) => col === 'Prognostiziert erreichbare Rente') + 1).toString().padStart(2, '0')}`,
    });
  }

  useEffect(() => {
    if (useOnlyThisRow === undefined) {
      // we try to detect the file type based on the first 7 rows
      const targetFirstRows = ['Kontakt-Straße', 'Kontakt-Hausnummer', 'Kontakt-Ort', 'Kontakt-PLZ', 'Kontakt-Postfach', 'Kontakt-Staat', 'Kontakt-URL'];
      // if there is more than one pension product in the input file (+ header)
      if (targetFirstRows.every((target, index) => currentFilePretransformed[0][index] === target)) {
        if (currentFilePretransformed.length > 2) {
          window.dispatchEvent(
            new CustomEvent('setDialog', {
              detail: {
                Component: ColumnMatcherRVUebersicht,
                props: { visible: true, inputFileObject: currentFilePretransformed, callback },
              },
            }),
          );
        } else if (currentFilePretransformed.length === 2) {
          setUseOnlyThisRow(1);
        }
      }
    }
  }, []);

  // --------------------------------------------------
  // INITIALISE COMPONENT
  // --------------------------------------------------

  // add custom columns from importFileMappings to the schema when importFiles or account object change
  // every time importFiles or account object changes, the entire fileObject is recalculated from scratch (to handle custom column changes)
  useEffect(() => {
    // check if there is any header row in transformationParams, if not - attempt to locate it
    if (transformationParams.headersInRow === undefined || transformationParams.headersInRow === null) {
      const detectedHeaderRow = detectHeaders(currentFilePretransformed, fileColumnStats);
      if (detectedHeaderRow !== undefined) setTransformationParams((prev) => ({ ...prev, headersInRow: detectedHeaderRow }));
    }

    // move current file to state
    const fileObject = transformInputObject(currentFilePretransformed, transformationParams?.headersInRow, fileColumnStats);
    setCurrentFileObject(fileObject);

    // once custom columns have been added, set dropState to the mappings from the account object
    // (if dropState is still empty - otherwise let it be, because that means it is not the first run)
    setDropState((prev) => {
      if (Object.keys(prev).length === 0) {
        const mappings = account.importFileMappings?.mappings || {};
        // skip this check if there are no mappings
        if (Object.keys(mappings).length > 0) {
          // check if all data model columns are still there (in case there has been a change in the data model)
          const missingColumns = Object.keys(mappings).filter((key) => !Object.keys(objectSchema.describe().fields).includes(key));
          if (missingColumns.length > 0) {
            // if there are missing columns, remove them from the mappings
            const newMappings = Object.entries(mappings).filter(([key, value]) => !missingColumns.includes(key));
            return Object.fromEntries(newMappings);
          }

          // check if imported mappings use any columns with indices exceeding the current highest column index (because user had imported a different file before)
          const highestIndex = Math.max(...Object.values(mappings).map((value) => Number(value.split('column')[1])));
          if (highestIndex > currentFileObject.headers.length) {
            // if yes, remove them from the mappings
            const newMappings = Object.entries(mappings).filter(([key, value]) => Number(value.split('column')[1]) <= currentFileObject.headers.length);
            return Object.fromEntries(newMappings);
          }
        }
        return account.importFileMappings?.mappings || {};
      }
      return prev;
    });
  }, [currentFilePretransformed, account]);

  // -------------------------------
  // GET TARGET COLUMNS FROM SCHEMA
  // -------------------------------

  // handle pensions, which need to display special quotes instead of quote | quoteBaseCurrency
  // for all other categories, return unaltered schema object
  function getSchemaEntries(category) {
    const originalSchema = Object.entries(objectSchema.describe().fields); // originalSchema returns an array of arrays [ fieldName, fieldObject ]

    // for all categories except pension, return the original schema without "specialQuotes", which have no relevance there
    if (category !== 'pension') return originalSchema.filter(([fieldName, fieldObject]) => !['specialQuotes'].includes(fieldName));

    return originalSchema
      .filter(([fieldName, fieldObject]) => !['currency', 'quote', 'quoteBaseCurrency', 'specialQuotes'].includes(fieldName))
      .concat(customQuoteTypesPension.map((x) => [x, { meta: { importOptional: true } }]));
  }

  // .describe() returns .fields: { id: { name, type, label, meta, ...}, date: { ... }, ... };
  // fields in schema can have a hideFromImport meta-property, which is used to hide columns from the import process
  // 'columns' needs to be [{ id, optional }, ...]
  const columns = getSchemaEntries(account.category)
    .filter(([key, value]) => !value.meta?.hideFromImport)
    .map(([key, value]) => ({ id: key, optional: value.meta?.importOptional || value.nullable }));

  // -------------------------------
  // PREPARE OUTPUT / HANDLE SAVE
  // -------------------------------

  // used to test if the form is ready as well as before submitting
  // fileObject is the object with headers and rows (transformed input file + custom columns)
  // this takes rows from fileObject and maps them to the data model columns according to what is in dropState
  // FIXME this needs to be adjusted to handle the special case in pension
  // get rid of special quotes that have not been provided by user or in the file
  async function transformAndValidateOutput(fileObject, alertOnError = true) {
    // clear validation errors state
    setValidationErrors([]);

    // for each row in fileObject get only the columns of the indices present in dropState
    // (so the columns that the user mapped to the data model columns)
    // and form a transaction object
    const output = fileObject.rows
      .filter((row, index) => useOnlyThisRow === undefined || index === useOnlyThisRow - 1) // if useOnlyThisRow is defined, only use that row
      .map((row) => {
        const entity = {};
        Object.entries(dropState).forEach(([key, value]) => {
          const index = value.split('column')[1] - 1;
          if (debugLevel > 2) console.log('DEBUG entity before', key, value, 'in row', row, 'current index', index, row[index]);
          if (['quantity', 'accountCurrencyAmount', 'fxAmount', 'transactionAmount'].includes(key)) {
            if (!decimalRef.current) {
              entity[key] = convertStringToNumber(
                row[index],
                fileObject.rows.map((r) => r[index]),
              );
            } else {
              entity[key] = convertStringToNumber(row[index]);
            }
          } else {
            entity[key] = row[index] ?? null;
          }
        });

        entity.date = dayjs(entity.date, expectedDateFormats || ['DD.MM.YYYY', 'YYYY-MM-DD'])
          .utc(true)
          .valueOf(); // default in case somehow the expectedDateFormats are not set

        if (displayedComponentMode === 'transactions') {
          if (debugLevel > 2) console.log('DEBUG ColumnMatcher: entity', entity);
          // add id
          entity.id = nanoid();
          // add attributes derived from account
          if (account.category === 'deposits' && !entity.currency) entity.currency = account.currency; // realEstate only allows quotes
          if (account.category === 'stocks') entity.transactionType = entity.transactionAmount >= 0 ? 'purchase' : 'sale'; // FIXME this assumes there will never be 'split' transactions etc.
          if (account.category !== 'realEstate') entity.accountId = account.id; // realEstate only allows quotes, and quotes do not need account
        }
        if (displayedComponentMode === 'quotes') {
          entity.source = 'manual';
          if (!entity.currency) entity.currency = account.currency; // realEstate only allows quotes
          entity.assetId = account.id;
          entity.projectId = null;
          entity.importFlag = 'post'; // was 'put', not sure why?

          // special routine for pension category quotes
          if (account.category === 'pension') {
            entity.quoteBaseCurrency = 0;
            entity.quote = 0;
            entity.currency = account.currency;
            entity.assetId = account.id;
            entity.source = 'manual';
            entity.specialQuotes = [];
            if (entity.payoutOnlyContributionsToDate) entity.specialQuotes.push({ type: 'payoutOnlyContributionsToDate', quote: convertStringToNumber(entity.payoutOnlyContributionsToDate) });
            if (entity.payoutOnlyContributionsToDateIndexed) entity.specialQuotes.push({ type: 'payoutOnlyContributionsToDateIndexed', quote: convertStringToNumber(entity.payoutOnlyContributionsToDateIndexed) });
            if (entity.payoutAllPlannedContributions) entity.specialQuotes.push({ type: 'payoutAllPlannedContributions', quote: convertStringToNumber(entity.payoutAllPlannedContributions) });
            if (entity.payoutAllPlannedContributionsIndexed) entity.specialQuotes.push({ type: 'payoutAllPlannedContributionsIndexed', quote: convertStringToNumber(entity.payoutAllPlannedContributionsIndexed) });

            if (account.currency === baseCurrency) {
              entity.specialQuotes.forEach((q) => {
                // eslint-disable-next-line no-param-reassign
                q.quoteBaseCurrency = q.quote;
              });
            }
            // the case where account.currency is NOT base currency is handled in handleSave, because this runs every time anything changes in the form
          }
        }

        return entity;
      });
    if (debugLevel > 2) console.log('transformed output', output, arraySchema);

    // validation by casting the file data through yum.cast
    let validatedOutput = false;
    try {
      validatedOutput = arraySchema.validateSync(output, { stripUnknown: true, abortEarly: false });
    } catch (err) {
      if (debugLevel > 2) console.error('validation error', err.message, err.inner);
      setValidationErrors(err.inner);
      if (alertOnError) {
        setAlert({
          visible: true,
          id: 'errorsDetected',
          type: 'error',
        });
      }
    }
    return validatedOutput;
  }

  async function handleSave() {
    const validatedOutput = await transformAndValidateOutput(currentFileObject);

    // PENSION QUOTES ONLY:
    // in pensions we need to check if all specialQuotes have quoteBaseCurrency; if they don't, we need to ask the user to provide them
    if (displayedComponentMode === 'quotes' && account.category === 'pension' && account.currency !== baseCurrency) {
      const missingQuoteBaseCurrency = validatedOutput.some((Q) => Q.specialQuotes.length > 0 && Q.specialQuotes.some((q) => q.quoteBaseCurrency === 0));
      if (missingQuoteBaseCurrency) {
        try {
          const fxRate = await new Promise((resolve, reject) => {
            window.dispatchEvent(
              new CustomEvent('setDialog', {
                detail: {
                  Component: ColumnMatcherAddQBCToPension,
                  props: {
                    visible: true,
                    specialQuotes: validatedOutput.filter((Q) => Q.specialQuotes.length > 0).map((Q) => Q.specialQuotes)[0],
                    accountCurrency: account.currency,
                    baseCurrency,
                    callback: (_fxRate) => {
                      resolve(_fxRate);
                    },
                    reject,
                  },
                },
              }),
            );
          });

          // apply fxRate to generate quoteBaseCurrency in all specialQuotes for all rows of validatedOutput
          validatedOutput.forEach((Q) => {
            Q.specialQuotes.forEach((q) => {
              // eslint-disable-next-line no-param-reassign
              q.quoteBaseCurrency = q.quote * fxRate;
            });
          });
        } catch (err) {
          // if the user cancels, do nothing
          return;
        }
      }
    }

    if (validatedOutput) {
      setLoading(true);

      // wait for dispatches to return something
      Promise.all([
        // send mappings to backend (update Account object) -- this will set a signal { [depositsPostAccount]: true }
        dispatch(
          putAccount({
            data: {
              ...account,
              importFileMappings: {
                ...account.importFileMappings,
                ...transformationParams,
                mappings: dropState,
              },
            },
            category: account.category,
          }),
        ),
        // update transformed in parent state
        updateCurrentFileTransformed(validatedOutput), // this will update the transformed[currentFileIdx] state in parent
      ]).then(
        () => {
          setDisplayedComponent('duplicate');
        },
        (err) => {
          // negative case
          if (debugLevel > 2) console.error('error', err);
          dispatch(setMessage('fileUploadFailed'));
        },
      );
    }
  }

  // FIXME
  // react to the button pressed by the user in response to aboutToSave modal triggered above in handleSaveGrid
  useEffect(() => {
    if (alert.id === 'abandonUpload') {
      if (alert.buttonClicked === 'ok') {
        // if the user pressed ok, go back to table screen
        setAlert({ visible: false }); // reset the alert modal
        setDisplayedComponent('table');
        dispatch(setMessage('fileUploadAbortedSuccessfully')); // shows a toast message confirming that file upload cancelled
      }
    }
  }, [alert.buttonClicked]);

  // -------------------------------
  // ONGOING VALIDATION
  // -------------------------------

  // after every mapping change this validates existing state of mappings; enables the submit button if there are no errors
  // validation must react to dropState change, because doing it in handleDrop is too early (dropState is not updated yet)
  useEffect(() => {
    async function ongoingValidation() {
      // validate the form and - if it passes - set the formReadyCondition to true
      let validationResult;
      try {
        validationResult = await transformAndValidateOutput(currentFileObject, false); // no alert on error; this updates validationErrors
        // if no errors, set validationErrors to empty
        // setValidationErrors([]);
        if (debugLevel > 2) console.info('validation result', validationResult);
        setFormReadyToSubmit(validationResult);
      } catch (err) {
        // do nothing, we are just checking if it is ready to unblock the button
      }
      // determine if all the required fields are filled out
      if (debugLevel > 2) {
        console.log(
          'columns',
          columns,
          'dropState',
          dropState,
          columns.filter((column) => !column.optional).every((column) => Object.keys(dropState).includes(column.id)),
        );
      }
      setAllFieldsAssigned(columns.filter((column) => !column.optional).every((column) => Object.keys(dropState).includes(column.id)));
    }

    ongoingValidation();
  }, [dropState]);

  // -------------------------------
  // HANDLE DRAG + DROP
  // -------------------------------

  function handleDragStart(e) {
    // the FileColumn component needs an entire object a prop, so we need to retrieve it
    setActiveFileColumn(currentFileObject.headers.find((header) => header.id === e.active.id));
  }

  function handleDrop(e) {
    // disable DropOverlay
    setActiveFileColumn(null);

    // when dropped on a droppable element:
    // - e.over.id --> which droppable column id are we over (null if none)
    // - e.active.id --> id of the component being dragged

    // check if trying to drop inside an already occupied column
    // if e.over.id exists in dropState, it means this column is occupied
    // if (e.over && !Object.entries(dropState).some(([key, value]) => value === e.over.id)) {
    if (e.over && !Object.keys(dropState).includes(e.over.id)) {
      // remove the dragged element from any previously occupied data model column, if it is there
      setDropState((prevDropState) => {
        const dropStateNoPrevColumn = Object.entries(prevDropState)
          .filter(([key, value]) => value !== e.active.id) // leave only those key-value pairs whose value is not the dragged element
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}); // bring it back to an object
        return { ...dropStateNoPrevColumn, [e.over.id]: e.active.id }; // add the dragged element to the new column
      });
    }

    // if dropped outside a droppable element (e.over.id is null), go through dropState and remove the dropped element
    if (!e.over) {
      // if (debugLevel > 2) console.log('dropped outside', e.active.id, 'dropState', dropState);
      setDropState(
        Object.entries(dropState)
          .filter(([key, value]) => value !== e.active.id)
          .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}),
      );
    }
  }

  function handleHeaderRowChange(newValue) {
    if (newValue < 1) setTransformationParams((prev) => ({ ...prev, headersInRow: 0 }));
    setTransformationParams((prev) => ({ ...prev, headersInRow: Number(newValue) - 1 }));
    // recalculate headers + rows
    setCurrentFileObject(transformInputObject(currentFilePretransformed, Number(newValue) - 1, fileColumnStats));
  }

  function handleOpenPreviewer(e) {
    // save matchings made by user to transformationParams, so that they are still here when the user is done editing data
    setTransformationParams((prev) => ({ ...prev, mappings: dropState }));
    // go to previewer
    setDisplayedComponent('previewer');
  }

  // CAUTION: a similar function is used in Previewer (check if update necessary there as well)
  // copy back ingested to pretransformed and reset status + reset previously saved commands
  function handleUndoTransform() {
    if (debugLevel > 2) console.log('DEBUG undoing transformation');
    updateCurrentFilePretransformed(JSON.parse(JSON.stringify(currentFileIngested))); // copy ingested to pretransforme

    // reset commands
    setTransformationParams((prev) => ({ ...prev, commands: [] }));

    setPretransformationApplied(false);
  }

  // if (debugLevel > 2) console.log('ColumnMatcher fileObject', currentFileObject);
  // if (debugLevel > 2) console.log('ColumnMatcher dropState', dropState);

  // -------------------------------
  // RENDER THE COMPONENT
  // -------------------------------

  return (
    // eslint-disable-next-line react/jsx-no-bind
    <>
      <section className="space-y-2 xl:flex xl:space-x-4 xl:items-center mt-2 md:mt-0">
        <div className="mt-2 sm:mt-0 rounded-md bg-white px-4 py-2 flex items-center space-x-2">
          <DocumentArrowDownIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
          <h2 className="text-xl md:text-2xl font-bold text-gray-700 break-all">{currentFileName}</h2>
        </div>
        {Object.keys(dropState).length === 0 && (
          // first upload message
          <div className="rounded-md bg-brandBlue-50 px-3 py-2" id="info-message">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-brandBlue-400" aria-hidden="true" />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-brandBlue-600">{t('firstUpload')}</p>
              </div>
            </div>
          </div>
        )}
      </section>

      <>
        <section className="ml-1 flex flex-rows gap-2 sm:gap-4">
          <ToolTipNoIcon info={t('cancelFileUpload.tooltip')}>
            <Button
              text={t('cancelFileUpload.label')}
              Icon={XMarkIcon}
              onClick={() => {
                setAlert({ visible: true, id: 'abandonUpload', buttons: ['ok', 'cancel'] });
                // do not send out a cleanup action to category service, because a process to clean up stuck files must be existing anyway
                // for the case that the user closes the app before the upload process is finished
              }}
              size="lg"
            />
          </ToolTipNoIcon>

          {/* reset columns button, only display above xs (otherwise no space) */}
          <ToolTipNoIcon info={t('resetColumns.tooltip')} classNameOwn="hidden xs:block">
            <Button
              text={t('resetColumns.label')}
              Icon={Reset}
              onClick={() => {
                setDropState({});
              }}
              size="lg"
            />
          </ToolTipNoIcon>
          {/* this only works if the file has more than 2 columns, but then it needs at least 4 columns to pass validation... */}
          <ToolTipNoIcon info={t('editLoadedFile.tooltip')}>
            <Button text={t('editLoadedFile.label')} Icon={PencilIcon} onClick={handleOpenPreviewer} size="lg" />
          </ToolTipNoIcon>
          {showSkipFileButton && (
            <ToolTipNoIcon info={t('skipThisFile.tooltip')}>
              <Button text={t('skipThisFile.label')} Icon={ForwardIcon} onClick={() => skipToNextFile()} size="lg" />
            </ToolTipNoIcon>
          )}
          <ToolTipNoIcon info={t('confirmAndContinue.tooltip')}>
            <Button text={t('confirmAndContinue.label')} Icon={CheckIcon} onClick={handleSave} spinnerOn={loading} enabled={formReadyToSubmit} withAccent size="lg" />
          </ToolTipNoIcon>

          {/* headers in row field (above LG) */}
          <div className="hidden lg:inline-flex gap-2 items-center">
            <label htmlFor="headerRow" className="block text-sm font-medium text-gray-700">
              {t('headersInRow.label')}
            </label>
            <input
              type="number"
              name="headerRow"
              id="headerRow"
              className="block w-14 rounded-md border-gray-300 pr-2 focus:border-brandBlue-500 focus:ring-brandBlue-500 sm:text-sm"
              value={transformationParams.headersInRow + 1 || 1}
              onChange={(e) => handleHeaderRowChange(e.target.value)}
            />
            <ToolTipNoIcon info={t('headersInRow.tooltip')}>
              <QuestionMarkCircleIcon className="h-5 w-5 text-gray-300" aria-hidden="true" />
            </ToolTipNoIcon>
          </div>

          {/* Errors button */}
          {allFieldsAssigned && validationErrors.length > 0 && (
            <ColumnMatcherErrorPopover validationErrorArray={validationErrors} setScrollToRow={setScrollToRow} handleOpenPreviewer={handleOpenPreviewer} />
          )}

          {pretransformationApplied && (
            <div className="rounded-md bg-brandBlue-50 px-3 py-1 flex items-center" id="info-message-transformed">
              <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-brandBlue-400" aria-hidden="true" />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between md:items-center">
                <p className="text-sm text-brandBlue-600">{t('fileTransformed')}</p>
                <Button text={t('fileTransformedUndo')} onClick={handleUndoTransform} noFrame size="xs" formatting="ml-2" textColor="text-brandBlue-700" textColorHover="text-brandBlue-800" />
              </div>
            </div>
          )}
        </section>
        {/* headers in row field (below LG) */}
        <div className="inline-flex lg:hidden gap-2 items-center">
          <label htmlFor="headerRow" className="block text-sm font-medium text-gray-700">
            {t('headersInRow.label')}
          </label>
          <input
            type="number"
            name="headerRow"
            id="headerRow"
            className="block w-14 rounded-md border-gray-300 pr-2 focus:border-brandBlue-500 focus:ring-brandBlue-500 sm:text-sm"
            value={transformationParams.headersInRow + 1 || 1}
            onChange={(e) => handleHeaderRowChange(e.target.value)}
          />
          <ToolTipNoIcon info={t('headersInRow.tooltip')}>
            <QuestionMarkCircleIcon className="h-5 w-5 text-gray-300" aria-hidden="true" />
          </ToolTipNoIcon>
        </div>

        {/* FILE COLUMNS */}

        <DndContext onDragStart={handleDragStart} onDragEnd={handleDrop} id="dnd-area">
          <div className="relative w-full h-full" ref={componentRef}>
            {/* ^^ parent container for keeping the div with fields in place while the columns scroll */}
            {previewState && <FilePreview previewState={previewState} setPreviewState={setPreviewState} fileObject={currentFileObject} />}
            <div className="bg-gray-50 w-full h-full flex flex-col justify-between">
              {/* ^^ parent container for horizontal scrolling */}
              <div className="w-full bg-gray-50">
                <div className="flex gap-px divide-x divide-gray-200 overflow-x-auto" id="data-model-columns-container">
                  {columns.map((col, idx) => (
                    <DataModelColumn col={col.id} idx={idx} key={col.id} lastInRow={idx === columns.length - 1} category={account.category} optional={col.optional}>
                      {/* if this column's id 'droppable-Date' is a value in any of the properties of dropState,
                  return the list of keys that this column's id is a value for */}
                      {Object.entries(dropState)
                        .filter(([key, value]) => key === col.id)
                        .map(([key, value]) => {
                          const obj = currentFileObject.headers.find((item) => item.id === value);
                          if (!obj) return <div />;
                          return <FileColumn obj={currentFileObject.headers.find((item) => item.id === value)} key={value} previewState={previewState} setPreviewState={setPreviewState} />;
                        })}
                    </DataModelColumn>
                  ))}
                </div>
              </div>
              <div className="mx-auto text-center text-xs text-gray-500 py-8">
                <p className="text-lg font-bold pb-1">{t('columnMatcherHeader')}</p>
                <p className="prose text-center text-xs text-gray-400 mx-auto">{t('columnMatcherInfo')}</p>
              </div>
              <div className="overflow-x-scroll">
                <div className="shadow-sm rounded-lg inset-x-0 bottom-8 mx-auto bg-white overflow-x-scroll">
                  <div className="mt-3 px-6 py-3 flex gap-5" id="file-columns-container">
                    {(currentFileObject.headers || [])
                      // show only those fileColumns, for which there are no keys in the dropState object or for which the value has been set to null before
                      .filter((x) => !Object.values(dropState).includes(x.id))
                      .map((obj, idx) => (
                        <FileColumn key={idx} obj={obj} setPreviewState={setPreviewState} />
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <DragOverlay>{activeFileColumn ? <FileColumn obj={activeFileColumn} previewState={previewState} setPreviewState={setPreviewState} /> : null}</DragOverlay>
          </div>
        </DndContext>
      </>
    </>
  );
}
ColumnMatcherInner.propTypes = {
  currentFileIngested: PropTypes.objectOf(PropTypes.any).isRequired,
  currentFileName: PropTypes.string.isRequired,
  transformationParams: PropTypes.objectOf(PropTypes.any).isRequired,
  setTransformationParams: PropTypes.func.isRequired,
  updateCurrentFileTransformed: PropTypes.func.isRequired,
  currentFilePretransformed: PropTypes.objectOf(PropTypes.any).isRequired,
  updateCurrentFilePretransformed: PropTypes.func.isRequired,
  showSkipFileButton: PropTypes.bool.isRequired,
  skipToNextFile: PropTypes.func.isRequired,
  setDisplayedComponent: PropTypes.func.isRequired,
  displayedComponentMode: PropTypes.string.isRequired,
  alert: PropTypes.objectOf(PropTypes.any).isRequired,
  setAlert: PropTypes.func.isRequired,
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  pretransformationApplied: PropTypes.bool.isRequired,
  setPretransformationApplied: PropTypes.func.isRequired,
  setScrollToRow: PropTypes.func.isRequired,
};

// this component needs to make sure that ingested[] is moved - with or without transformations - into pretransformed[]
export default function ColumnMatcherOuter(props) {
  const { account, transformationParams, currentFileIngested, currentFilePretransformed, updateCurrentFilePretransformed, setPretransformationApplied } = props;

  // -----------------------------------
  // APPLY SAVED PRETRANSFORMATIONS
  // -----------------------------------

  // we are doing that very early in the component initialisation process, so that we can use those data later on
  useEffect(() => {
    // if currentFilePretransformed is missing, apply the transformations on ingested file (if any) and copy it over to pretransformed
    if (!currentFilePretransformed && account.tags?.applyImportTransformationsAutomatically !== false && transformationParams.commands && transformationParams.commands.length > 0) {
      // TODO check why this does not work
      const transformedData = applyCommands(currentFileIngested, transformationParams.commands, transformationParams.headersInRow);
      // then set the pretransformed data in the state
      updateCurrentFilePretransformed(JSON.parse(JSON.stringify(transformedData)));
      setPretransformationApplied(true);
    } else if (!currentFilePretransformed) {
      // just set the pretransformed data in the state as a copy of the ingested data
      updateCurrentFilePretransformed(JSON.parse(JSON.stringify(currentFileIngested)));
      setPretransformationApplied(false);
    }
  }, []);

  if (!currentFilePretransformed) return true;

  return <ColumnMatcherInner {...props} />;
}
ColumnMatcherOuter.propTypes = {
  currentFileIngested: PropTypes.objectOf(PropTypes.any).isRequired,
  currentFileName: PropTypes.string.isRequired,
  transformationParams: PropTypes.objectOf(PropTypes.any).isRequired,
  setTransformationParams: PropTypes.func.isRequired,
  updateCurrentFileTransformed: PropTypes.func.isRequired,
  currentFilePretransformed: PropTypes.objectOf(PropTypes.any).isRequired,
  updateCurrentFilePretransformed: PropTypes.func.isRequired,
  showSkipFileButton: PropTypes.bool.isRequired,
  skipToNextFile: PropTypes.func.isRequired,
  setDisplayedComponent: PropTypes.func.isRequired,
  displayedComponentMode: PropTypes.string.isRequired,
  alert: PropTypes.objectOf(PropTypes.any).isRequired,
  setAlert: PropTypes.func.isRequired,
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  setPretransformationApplied: PropTypes.func.isRequired,
  pretransformationApplied: PropTypes.bool.isRequired,
  setScrollToRow: PropTypes.func.isRequired,
};
