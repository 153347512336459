/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import DialogSelectFile from '../../../elements/DialogSelectFile';
import ColumnMatcher from './ColumnMatcher';
import Previewer from './Previewer';

// this component should hold the states of the file import process
// it should also hold all the transformation logic

export default function ImportFile(props) {
  const { displayedComponent, account } = props;

  const defaultTransformationParams = {
    ...account.importFileMappings,
    mappings: account.importFileMappings?.mappings || {}, // object - for each column -- object of mappings
    headersInRow: account.importFileMappings?.headersInRow || null, // null means no headers, 0 means headers in row of index 0
    decimalChar: account.importFileMappings?.decimalChar || ',',
    dateFormat: account.importFileMappings?.dateFormat || 'YYYY-MM-DD',
    commands: account.importFileMappings?.commands || [], // ordered array of objects { verb, arg, value }
    filters: account.importFileMappings?.filters || [], // ordered array of arrays of objects { verb, arg, value }
  };

  const [fileNames, setFileNames] = useState([]); // array - for each file -- array of string
  const [currentFileIdx, setCurrentFileIdx] = useState(0); // number - index of the current file being processed
  const [ingested, setIngested] = useState(null); // array - for each file -- array (rows) of arrays (fields of a row)
  const [pretransformed, setPretransformed] = useState(null); // array - for each file -- array (rows) of arrays (fields of a row)
  const [transformed, setTransformed] = useState(null); // array - for each file -- array (rows) of objects (fields of a row)
  const [transformationParams, setTransformationParams] = useState(defaultTransformationParams); // object - for account -- object of transformation params
  const [scrollToRow, setScrollToRow] = useState(null); // number - index of the row to scroll to on opening Previewer
  // ↑↑ used to take user from ColumnMatch > errors directly to where the error can be fixed
  const [pretransformationApplied, setPretransformationApplied] = useState(null); // boolean - whether the pretransformation has been applied (applied by ColumnMatcher on init)

  const currentFileIngested = ingested?.[currentFileIdx];
  const currentFilePretransformed = pretransformed?.[currentFileIdx];
  const currentFileTransformed = transformed?.[currentFileIdx];
  const currentFileName = fileNames?.[currentFileIdx];

  function updateCurrentFileTransformed(payload) {
    const newTransformed = JSON.parse(JSON.stringify(transformed)) || [];
    newTransformed[currentFileIdx] = JSON.parse(JSON.stringify(payload));
    setTransformed(newTransformed);
  }

  function updateCurrentFilePretransformed(payload) {
    const newPretransformed = JSON.parse(JSON.stringify(pretransformed)) || [];
    newPretransformed[currentFileIdx] = JSON.parse(JSON.stringify(payload)); // FIXME where do we do the copy?
    setPretransformed(newPretransformed);
  }

  function nextFile() {
    if (ingested && currentFileIdx + 1 < ingested.length) {
      // reset file-related state
      setCurrentFileIdx(currentFileIdx + 1); // not using (prev) => to handle strict mode double execution in dev
      // eslint-disable-next-line react/destructuring-assignment
      props.setDisplayedComponent('column');
    }
  }

  function fileProcessingComplete() {
    // if this is not the last file, move to the next file
    if (ingested && currentFileIdx + 1 < ingested.length) {
      nextFile();
    } else {
      // eslint-disable-next-line react/destructuring-assignment
      props.setDisplayedComponent('table');
    }
  }

  // show the skip file button if there is more than one file and the current file is not the last one
  // if the user wants to skip the last one, they can cancel
  const showSkipFileButton = ingested && ingested.length > 1 && currentFileIdx + 1 < ingested.length;

  switch (displayedComponent) {
    case 'import':
      return <DialogSelectFile {...props} setIngested={setIngested} setFileNames={setFileNames} />;
    case 'column':
      return (
        <ColumnMatcher
          {...props}
          currentFileIngested={currentFileIngested}
          currentFileName={currentFileName}
          currentFilePretransformed={currentFilePretransformed}
          updateCurrentFilePretransformed={updateCurrentFilePretransformed}
          transformationParams={transformationParams}
          setTransformationParams={setTransformationParams}
          updateCurrentFileTransformed={updateCurrentFileTransformed}
          showSkipFileButton={showSkipFileButton}
          skipToNextFile={nextFile}
          setPretransformationApplied={setPretransformationApplied}
          setScrollToRow={setScrollToRow}
          pretransformationApplied={pretransformationApplied}
        />
      );
    case 'duplicate':
      // prettier-ignore
      return (
        <Previewer
          {...props}
          currentFileName={currentFileName}
          currentFileTransformed={currentFileTransformed}
          updateCurrentFileTransformed={updateCurrentFileTransformed}
          fileProcessingComplete={fileProcessingComplete}
        />
      );
    case 'previewer':
      return (
        <Previewer
          {...props}
          currentFileName={currentFileName}
          currentFilePretransformed={currentFilePretransformed}
          currentFileIngested={currentFileIngested} // to do undo pretransformation
          updateCurrentFilePretransformed={updateCurrentFilePretransformed}
          transformationParams={transformationParams}
          setTransformationParams={setTransformationParams}
          scrollToRow={scrollToRow}
          setScrollToRow={setScrollToRow}
          pretransformationApplied={pretransformationApplied}
          setPretransformationApplied={setPretransformationApplied}
        />
      );
    default:
      return true;
  }
}
ImportFile.propTypes = {
  displayedComponent: PropTypes.string.isRequired,
  setDisplayedComponent: PropTypes.func.isRequired,
  account: PropTypes.object.isRequired,
};
