/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useState } from 'react';
import { PivotData } from 'react-pivottable/Utilities';
import { ResponsiveAreaBump as AreaBump } from '@nivo/bump';
import { useSelector } from 'react-redux';
import { globalAccountsView } from '../../../redux/reducers/data';
import { decor as colorsByCategory } from '../../../elements/tileDecor';

function makeNivoRenderer(NivoChart, traceOptions = {}, layoutOptions = {}, transpose = false) {
  return function NivoRenderer(props) {
    if (!props.data || props.data.length === 0) return <div />;

    const pivotData = new PivotData(props);
    const columnNames = pivotData.getRowKeys();
    const barNames = pivotData.getColKeys();

    const isViewerMode = useSelector((state) => state.viewer?.metadata?.publishedOn);
    const accounts = isViewerMode ? useSelector((state) => state.viewer.accounts) : useSelector(globalAccountsView);

    let fullAggName = props.aggregatorName;
    const numInputs = props.aggregators[fullAggName]([])().numInputs || 0;
    if (numInputs !== 0) {
      fullAggName += ` of ${props.vals.slice(0, numInputs).join(', ')}`;
    }

    // console.log('AreaBump: rowKeys', columnNames); // all the possible combinations of main key category fields [[2015, 'DKB Giro'], [2017, 'Festgeld'],[2019, 'Festgeld'] ...]
    // console.log('AreaBump: colKeys', barNames); // all the possible combinations of the secondary key category fields [['American Express', 'EUR'], ['Arbeitgeber AG', 'EUR'], ['Avis AG', 'EUR']...]
    // console.log('AreaBump: pivotData with props', pivotData);

    // rowKeys and colKeys have all the needed combinations of keys already
    // e.g. [[2015, 'EUR'], [2015, 'USD'], [2016, 'EUR'], [2017, 'EUR']]
    // even if there are more than 1 attribute in each group
    // so all that needs to happen is to produce for each object (which is an array) in rowKeys
    // an object with the joinedKey
    const columnFieldNames = pivotData.props.rows; // ["Date (Year)", "accountName"]
    const barFieldNames = pivotData.props.cols; // names of the fields that go to secondary category ["otherParty", "currency"]

    // console.log('AreaBump: rowNames', columnFieldNames);
    // console.log('AreaBump: colNames', barFieldNames);

    function joinKeys(keys) {
      return typeof keys === 'object' ? keys.join(String.fromCharCode(0)) : keys;
    }

    const randomColors = ['#68BBF7', '#B8E08D', '#d3a0df', '#ffe666', '#f26065', '#6265ad'];

    // if the accountName is a name of an account, return the color of the account
    // otherwise return a random color from the approved array
    function getAccountColor(accountName) {
      const account = accounts.find((acc) => acc.name === accountName);
      if (account) {
        return colorsByCategory[account.category].color.hex;
      }
      return randomColors[Math.floor(Math.random() * randomColors.length)];
    }

    // FIXME this organises data by ACCOUNT NAME; if two accounts happen to have the same name, the data will be merged
    // expects Date (Year) / Date (Month) as main category, account or category as secondary category
    const chartData = barNames.map((colKey) => {
      const barNamesJoined = joinKeys(colKey); // in case there is more than one per array
      const colData = columnNames.map((rowKey) => {
        const columnNamesJoined = joinKeys(rowKey);
        // console.log('AreaBump: columnNamesJoined', columnNamesJoined);
        // console.log('AreaBump: barFieldNames[0]:', barFieldNames[0], 'columnNamesJoined', columnNamesJoined, 'barNamesJoined:', barNamesJoined, 'columnFieldNames[0]:', columnFieldNames[0]);
        // console.log(
        //   'AreaBump: pivotData.props.data',
        //   pivotData.props.data.filter((d) => d[barFieldNames[0]] === barNamesJoined && d[columnFieldNames[0]] === columnNamesJoined),
        // );

        // TODO: this is a chart component, so all it should do is display the data provided by parent (no logic!)
        // i.e. for the annual chart this should already receive one object per bar and per column

        const y = pivotData.props.data
          .filter((d) => d[barFieldNames[0]] === barNamesJoined && d[columnFieldNames[0]] === columnNamesJoined) // this will return several rows if colKeys are years
          // this filter will return all the rows that match the current columnNamesJoined and barNamesJoined
          .reduce((prev, curr) => prev + curr.value, 0);

        return {
          x: columnNamesJoined,
          y: Math.abs(y),
          // this takes the latest date's value, if there are several rows
        };
      });
      return {
        id: barNamesJoined,
        data: colData,
      };
    });

    // console.log('AreaBump: chartData (output)', chartData);

    const colors = chartData.map((d) => getAccountColor(d.id));

    // AreaBump charts require a data array with the following structure:
    // [
    //   {
    //     "id": "DEPOT1",
    //     "data": [
    //       {
    //         "x": 2000,
    //         "y": 14
    //       },
    //       {
    //         "x": 2001,
    //         "y": 20
    //       }, ...

    function getAggregate(parents) {
      return pivotData.props.data.reduce((prev, curr) => {
        if (curr.formattingLevel !== null) return prev;
        // go through all summary rows, lowest level only
        // only add value when ALL the parent level's conditions from 'parents' are met
        // i.e. if parents is [['category', 'deposits'], ['accountName', 'DKB Giro']]
        // then only add value when both conditions are met
        if (parents.every((parent) => curr[parent[0]] === parent[1])) {
          return prev + curr.currentValue;
        }
        return prev;
      }, 0);
    }

    const commonProps = {
      margin: {
        top: 10,
        right: 130,
        bottom: 36,
        left: 130,
      },
      spacing: 8, // between bands
      padding: 0.2, // influences shape of band curves
      colors,
      theme: {
        fontFamily: 'Lato',
        tooltip: {
          container: {
            background: '#333',
            color: '#fff',
          },
        },
      },
      id: 'name',
      data: chartData,
      startLabel: 'id',
      startLabelTextColor: { from: 'color', modifiers: [['darker', 0.4]] },
      endLabel: 'id',
      endLabelTextColor: { from: 'color', modifiers: [['darker', 0.4]] },
      ...pivotData.props.layoutOptions,
      // defs: [
      //   {
      //     id: 'dots',
      //     type: 'patternDots',
      //     background: 'inherit',
      //     color: '#38bcb2',
      //     size: 4,
      //     padding: 1,
      //     stagger: true,
      //   },
      //   {
      //     id: 'lines',
      //     type: 'patternLines',
      //     background: 'inherit',
      //     color: '#eed312',
      //     rotation: -45,
      //     lineWidth: 6,
      //     spacing: 10,
      //   },
      // ],
      // fill: [
      //   {
      //     match: {
      //       id: 'realEstate',
      //     },
      //     id: 'dots',
      //   },
      //   {
      //     match: {
      //       id: 'deposits',
      //     },
      //     id: 'lines',
      //   },
      // ],
    };

    return (
      <div className="relative w-full h-full">
        <NivoChart {...commonProps} />
      </div>
    );
  };
}

export default function createNivoRenderers() {
  return {
    'Area chart': makeNivoRenderer(AreaBump),
  };
}
