/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Switch } from '@headlessui/react';
import { clearAlert } from '../../redux/actions/message';
import GrowthRate from './GrowthRate';
import RealEstateValuations from './RealEstateValuations';
import AccountSettingsMetals from './AccountSettingsMetals';
import DividendRates from '../settings/DividendRates';
import CategoryGrowthRates from '../settings/CategoryGrowthRates';
import ButtonBar from './ButtonBar';
import { getButtonsToShow } from './TableLayout';
import { putAccount } from '../../redux/reducers/data';
import Button from '../../elements/Button';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function AccountSettings({ account: propAccount, setDisplayedComponent, displayedComponentMode, setDisplayedComponentMode, handleSync, accountSyncStatus }) {
  // need to get account information from redux store, as the prop is not updated in Tile when the account object changes in store
  const account = useSelector((state) => state.data[propAccount.category].accounts).filter((a) => a.id === propAccount.id)[0];

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const alert = useSelector((state) => state.message.alert);

  const [autoImportPretransformation, setAutoImportPretransformation] = useState(account.tags.applyImportTransformationsAutomatically);
  const [autoImportPretransformationDirty, setAutoImportPretransformationDirty] = useState(false);
  const [autoImportPretransformationLoading, setAutoImportPretransformationLoading] = useState(false);

  function handleSaveAutoImportPretransformation() {
    setAutoImportPretransformationLoading(true);
    dispatch(
      putAccount({
        data: { ...account, tags: { ...account.tags, applyImportTransformationsAutomatically: autoImportPretransformation } },
        category: account.category,
      }),
    ).then(
      () => {
        // handle ok
        setAutoImportPretransformationLoading(false);
        setAutoImportPretransformationDirty(false);
      },
      () => {
        // handle error
        setAutoImportPretransformationLoading(false);
      },
    );
  }

  return (
    <>
      {/* BUTTONS */}
      <ButtonBar
        buttonsToShow={getButtonsToShow(account.category, displayedComponentMode)}
        displayedComponent="settings"
        setDisplayedComponent={setDisplayedComponent}
        displayedComponentMode={displayedComponentMode}
        setDisplayedComponentMode={setDisplayedComponentMode}
        handleSync={handleSync}
        userChangesPresent={false}
        accountSyncStatus={accountSyncStatus}
      />

      {/* CONTENTS */}
      <form className="space-y-8 w-full overflow-y-auto scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 hover:scrollbar-thumb-zinc-400" id="account-details-settings">
        <section aria-labelledby="settings" className="h-full">
          <div className="shadow w-full sm:rounded-md bg-white">
            {/* SIMULATED GROWTH RATE */}

            <div className="py-6 px-4 sm:p-6 space-y-6">
              <div>
                <h2 id="payment-details-heading" className="text-lg font-medium leading-6 text-gray-900">
                  {t('app:accountDetails.settings.simulatedAccountGrowth.heading')}
                </h2>
              </div>

              {['stocks', 'crypto'].includes(account.category) ? (
                <>
                  <CategoryGrowthRates setIsDirty={() => {}} displayOnly={account.category} />
                  {account.category === 'stocks' && <DividendRates setIsDirty={() => {}} />}
                </>
              ) : (
                <GrowthRate propAccount={propAccount} />
              )}

              {account.category === 'realEstate' && (
                <>
                  <div>
                    <h2 id="payment-details-heading" className="text-lg font-medium leading-6 text-gray-900">
                      {t('app:accountDetails.settings.realEstateValuations.heading')}
                    </h2>
                  </div>
                  <div className="mt-4 grid grid-cols-6 gap-6">
                    <div className="col-span-2">
                      <p className="mt-1 mb-4 text-xs xs:text-sm text-gray-500">{t('app:accountDetails.settings.realEstateValuations.label')}</p>
                    </div>
                    <RealEstateValuations account={account} />
                  </div>
                </>
              )}

              {account.category === 'metals' && <AccountSettingsMetals propAccount={propAccount} />}
            </div>

            {/* AUTOMATIC PRETRANSFORMATION */}

            {['deposits', 'stocks', 'realEstate', 'pension', 'loans'].includes(account.category) && (
              <div className="py-6 px-4 sm:p-6 space-y-6">
                <h2 id="auto-import-pretransformation-heading" className="text-lg font-medium leading-6 text-gray-900">
                  {t('app:accountDetails.settings.autoImportPretransformation.heading')}
                </h2>
                <div className="grid grid-cols-6">
                  <p className="prose mt-4 text-xs xs:text-sm text-gray-500 col-span-2">{t('app:accountDetails.settings.autoImportPretransformation.body')}</p>
                  <Switch.Group as="div" className="flex items-center col-span-2">
                    <Switch
                      checked={autoImportPretransformation}
                      onChange={() => {
                        setAutoImportPretransformation(!autoImportPretransformation);
                        setAutoImportPretransformationDirty(true);
                      }}
                      className={classNames(
                        autoImportPretransformation ? 'bg-brandBlue-500' : 'bg-gray-200',
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-600',
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          autoImportPretransformation ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                        )}
                      />
                    </Switch>
                    <Switch.Label as="span" className="ml-3">
                      <span className="text-sm font-medium text-gray-900">{t('app:accountDetails.settings.autoImportPretransformation.label')}</span>
                    </Switch.Label>
                  </Switch.Group>
                  {autoImportPretransformationDirty && (
                    <Button text="Save" withAccent onClick={handleSaveAutoImportPretransformation} spinnerOn={autoImportPretransformationLoading} size="md" formatting="mt-4 col-start-3 w-20" />
                  )}
                </div>
              </div>
            )}
          </div>
        </section>
      </form>
    </>
  );
}
