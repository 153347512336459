/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect, shallowEqual } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { useTranslation } from 'react-i18next';
import { DocumentArrowDownIcon } from '@heroicons/react/24/outline';
import Grid from '../Grid';
import ButtonBar from '../ButtonBar';
import getGridLayout from '../gridLayouts';
import * as importDeposits from './deposits';
import * as importStocks from './stocksTransactions';
import * as importRealEstateQuotes from './realEstate';
import * as importLoans from './loans';
import { putQuotes } from '../../../redux/reducers/quotes';
import { postFileImportData } from '../../../redux/reducers/data';
import * as common from '../params/common';
import MiniSpinner from '../../../misc/MiniSpinner';

function sortArrayByDateAndSource(a, b) {
  // First sort by descending date
  if (a.date > b.date) return -1;
  if (a.date < b.date) return 1;

  // If dates are the same, sort by ascending source
  if (a.source < b.source) return -1;
  if (a.source > b.source) return 1;

  return 0;
}

function markDuplicateTransactions(transactions, categoryModule) {
  for (let i = 0; i < transactions.length; i += 1) {
    const transaction1 = transactions[i];
    for (let j = i + 1; j < transactions.length; j += 1) {
      const transaction2 = transactions[j];
      if (categoryModule.checkIfDuplicate(transaction1, transaction2)) {
        transaction1.isDuplicate = true;
        transaction2.isDuplicate = true;
      }
    }
  }
  return transactions;
}

// used to show duplicates (the user gets to set "keep" flag for each transaction) or edit the input file (user gets to change anything they want)
// use displayedComponent='duplicate' or 'previewer' to switch between the two
export default function Previewer({
  account,
  displayedComponent,
  setDisplayedComponent,
  displayedComponentMode,
  setDisplayedComponentMode,
  currentFileIngested,
  currentFilePretransformed,
  updateCurrentFilePretransformed,
  currentFileTransformed,
  currentFileName,
  transformationParams,
  setTransformationParams,
  fileProcessingComplete,
  userChangesPresent,
  setUserChangesPresent,
  tableState,
  setAccount,
  pretransformationApplied,
  setPretransformationApplied, // when doing undo, to turn the info message off
  scrollToRow,
  setScrollToRow,
}) {
  // expects a concatenated, single array of all transactions from all input files
  // runs the duplicate check; if duplicates found, they are displayed in a Grid for use to take action on
  // if no duplicates found, the data are posted

  let categoryModule;
  switch (account.category) {
    case 'realEstate': // !!! realEstate only has quotes enabled, so leaving this logic as is for now
      categoryModule = importRealEstateQuotes;
      break;
    case 'stocks':
      categoryModule = importStocks;
      break;
    case 'deposits':
      categoryModule = importDeposits;
      break;
    case 'loans':
      categoryModule = importLoans;
      break;
    default:
      categoryModule = {};
  }

  const [gridData, setGridData] = useState([]);
  const [runSave, setRunSave] = useState(false); // if this turns to true, the Grid component will execute handleSaveWrapper
  // we are using state to manage that becuase handleSaveWrapper must run in the context of Grid, not here

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const existingTransactionsSelector = createSelector(
    (state) => state.data[account.category].transactions,
    ($transactions) => {
      if (displayedComponent === 'previewer') return []; // save compute time
      return (
        $transactions
          .filter((tt) => tt.accountId === account.id)
          // (deposits only) handle case when someone already split an imported transaction
          .reduce((prev, curr) => {
            // this applies only to deposit transactions, so if this is not a deposit transaction, just return it
            if (curr.category !== 'deposits') return [...prev, curr];
            // first filter out transactions which do not have a parentId
            if (!curr.parentId) return [...prev, curr];
            // is the parent transaction already in prev?
            const parentTransactionIndex = prev.findIndex((tt) => tt.parentId === curr.parentId);
            // if there is none, this transaction is the first one to belong to that parentId supertransaction
            if (parentTransactionIndex === -1) return [...prev, curr];
            // otherwise add amount to the already existing parentId supertransaction
            prev.push({ ...prev[parentTransactionIndex], accountCurrencyAmount: prev[parentTransactionIndex].accountCurrencyAmount + curr.accountCurrencyAmount });
            // date, accountCurrencyAmount and otherPartyAccount are relevant for duplicate check, so we need to update them
            return prev;
          }, [])
          .map((tt) => ({ ...tt, source: 'app' }))
      );
    },
    {
      memoizeOptions: {
        resultEqualityCheck: shallowEqual,
      },
    },
  );

  const selectExistingTransactions = useSelector(existingTransactionsSelector);
  const selectTransformedData = currentFileTransformed;
  const dataForDuplicateCheck = [...currentFileTransformed.map((tt) => ({ ...tt, keepFlag: true, source: 'import' })), ...selectExistingTransactions];

  const gridLayout = displayedComponent === 'previewer' ? undefined : getGridLayout(account.category, displayedComponentMode, 'duplicate');

  // -------------------------------
  // PREPARE DATA FOR POST (helper)
  // -------------------------------

  // prepare data for post (used in initialisation when there are no duplicates or after the user does something in Grid)
  // only used in "duplicate" mode
  function prepareTransactionForPost(dataIn) {
    return (
      dataIn
        // handle transactions that were marked as duplicates (coming from Grid) only
        .map((item) => {
          const inputTransaction = selectTransformedData.find((transaction) => transaction.id === item.id);
          const importFlag = 'post'; // all imported transactions are new

          return {
            ...inputTransaction, // spread the original transaction from before Grid first
            ...item, // spread the modified transaction from Grid on top of that
            ...(displayedComponentMode === 'transactions' && { accountId: account.id }), // 'post' transactions won't have accountId or currency, so we need to add them here
            ...(account.category === 'deposits' && { currency: account.currency }), // add currency to deposits (stocks arrive with its own transactionCurrency)
            importFlag,
          };
        })
        // add all transactions that were not duplicates (they should be complete)
        .concat(markDuplicateTransactions(dataForDuplicateCheck, categoryModule).filter((transaction) => !transaction.isDuplicate))
        .map(categoryModule.outputTransformCategoryTransactions(account))
    ); // this is here for compatibility with the existing code, but it's not needed for deposits (it does nothing)
  }

  // ----------------------------
  // COMPONENT INITIALISATION
  // ----------------------------

  // duplicates logic: if there are no duplicates, post the data | if there are, setGridData to display them in Grid for the user to take action
  // previewer logic: just take the raw data from state and display them in Grid
  const myRef = React.createRef(false);
  useEffect(() => {
    if (!myRef.current) {
      // quotes bypass duplicate check and head straight to dispatch
      if (displayedComponent === 'duplicate' && displayedComponentMode === 'quotes') {
        myRef.current = true; // set myRef to prevent another run in strict mode
        dispatch(
          putQuotes({
            body: selectTransformedData,
            category: account.category,
            accountId: account.id,
          }),
        ).then(() => {
          fileProcessingComplete(); // in parent component
        });
      } else if (displayedComponent === 'duplicate' && selectTransformedData.length > 0) {
        // when this is run the first time after component renders, it already has the data in both selects
        // we can assume the incoming file is not empty (it's checked in DialogSelectFile)
        // run duplicate check
        myRef.current = true; // set myRef to prevent another run in strict mode
        const transactionsWithDuplicateFlag = markDuplicateTransactions(dataForDuplicateCheck, categoryModule);
        console.log('DEBUG Previewer > transactionsWithDuplicateFlag', transactionsWithDuplicateFlag);

        // if duplicates found, display them in a Grid for user to take action on
        // if no duplicates found, post the data
        if (transactionsWithDuplicateFlag.some((transaction) => transaction.isDuplicate)) {
          const prepDataForGrid = transactionsWithDuplicateFlag
            .filter((transaction) => transaction.isDuplicate)
            .map(({ isDuplicate, ...rest }) => rest)
            .map(categoryModule.categoryOrderedObject(account, displayedComponent))
            .sort(sortArrayByDateAndSource);
          setGridData(prepDataForGrid);
        } else {
          console.log('Previewer: no duplicates found');
          // post the data
          // isDuplicate, keepFlag and source will be removed by the action creator; filter by keepFlag and source will also be applied there
          // run general transformations from prepAndValidateOutputData (it is also run in Grid for duplicated data, but we need to run it here as well)
          // prepareDataForPost already gets all the non-duplicate transactions from the app and adds them to the data to be posted
          if (displayedComponentMode === 'transactions') {
            console.log('Previewer: posting data (transaction mode)');
            dispatch(
              postFileImportData({
                data: prepareTransactionForPost([]),
                category: account.category,
                accountId: account.id,
              }),
            ).then(() => {
              console.log('Previewer: file processing complete is about to run');
              fileProcessingComplete(); // in parent component
            });
          }
        }
      } else if (displayedComponent === 'previewer') {
        myRef.current = true; // set myRef to prevent another run in strict mode
        // currentFileIngested is an array of array, which is acceptable for Grid
        setGridData(currentFilePretransformed);
      }
    }
  }, []);

  // ------------------
  // TRANSFORM OUTPUT
  // ------------------

  // in "previewer" mode we will need to see array of array as output; in "duplocate" mode we need array of objects with proper formatting (for subsequent posting to backend)
  // (needed by Grid, needs gridLayout from context)
  function prepAndValidateOutputData(data) {
    // transform data from Grid to the "canonical" format in duplicate mode, but leave them be in previewer mode
    if (displayedComponent === 'duplicate') {
      const transformedData = data
        .filter((row) => row.join('').length > 0) // remove completely empty rows
        .map((row) => gridLayout.reduce((prev, curr, idx) => ({ ...prev, [curr.id]: row[idx] }), {})); // convert array of arrays to array of objects

      // remove grid formatting
      const dataAfterReformatting = common.removeGridFormatting(transformedData, gridLayout);

      // perform category-specific transformations, add importFlag
      const dataAfterCategorySpeficicTransformations = prepareTransactionForPost(dataAfterReformatting);

      return { status: 'success', data: dataAfterCategorySpeficicTransformations };
    }
    if (displayedComponent === 'previewer') {
      const transformedData = data.filter((row) => row.join('').length > 0); // remove completely empty rows

      return { status: 'success', data: transformedData };
    }
    return { status: 'error', data: [] };
  }

  // ------------------------------------------------
  // SEND ACTION HISTORY RECORDED BY GRID TO PARENT
  // ------------------------------------------------
  function postActionHistory(data) {
    // Grid will send it back here before saving
    // format is [{ command: 'deleteRows', args: [[1,2,3], true, false] }, ...]
    setTransformationParams((prev) => ({ ...prev, mappingSteps: data }));
  }

  // ----------------------------
  // PREPARE GRID SAVE FUNCTION
  // ----------------------------

  // in "duplicate" mode we can use the regular process with postCategoryItems function being imported from state through the categoryModule and passed to Grid
  // in "previewer" mode we need to return data to state of the parent component, which is category-independent, so let's just do it here for now
  let customPostCategoryItems;
  switch (displayedComponent) {
    case 'duplicate':
      customPostCategoryItems = categoryModule.postCategoryItems;
      break;
    case 'previewer':
      // update the state of the parent component (ImportFile) with Grid's output
      customPostCategoryItems = (data) => {
        // reattach rows above headers (we want to keep headersInRows for pretransformation next time user uploads the file)
        // deep copy handled in the function
        console.log('DEBUG Previewer > customPostCategoryItems > received data', data, 'adding data', gridData.slice(0, transformationParams.headersInRow || 0));
        updateCurrentFilePretransformed(gridData.slice(0, transformationParams.headersInRow || 0).concat(data));
        return { status: 'success' }; // for compatibility with dispatch in Grid
      };
      break;
    default:
      customPostCategoryItems = () => {};
  }

  // -------------------------------
  // HANDLE UNDO PRETRANSFORMATION
  // -------------------------------

  // copy back ingested to pretransformed
  // CAUTION: similar function is in ColumnMatcher, consider reflecting updates there as well
  function handleUndoTransform() {
    console.log('DEBUG undoing transformation');
    updateCurrentFilePretransformed(JSON.parse(JSON.stringify(currentFileIngested))); // copy ingested to pretransformed
    setGridData(JSON.parse(JSON.stringify(currentFileIngested))); // update Grid data (try to create a deep copy)

    // reset commands
    setTransformationParams((prev) => ({ ...prev, commands: [] }));

    setPretransformationApplied(false);
    window.dispatchEvent(new CustomEvent('gridReloadData', { detail: currentFileIngested })); // pass memory reference with event (tried passing gridData via props, but it is too slow)
  }

  // ------------------
  // RENDER COMPONENT
  // ------------------

  if (gridData.length === 0) {
    return (
      <div className="w-full h-full grid grid-cols-1 place-items-center gap-2">
        <MiniSpinner className="text-gray-500 mx-auto h-5 w-5 animate-spin" />
      </div>
    );
  }
  return (
    <>
      <div className="mt-2 sm:mt-0 rounded-md bg-white px-4 py-2 flex items-center space-x-2">
        <DocumentArrowDownIcon className="h-6 w-6 text-gray-500" aria-hidden="true" />
        <h2 className="text-xl md:text-2xl font-bold text-gray-700 break-all">{currentFileName}</h2>
      </div>
      <ButtonBar
        buttonsToShow={
          displayedComponent === 'previewer' ? ['submit-cancel', 'decimalChar', 'headersInRow', pretransformationApplied ? 'infoTransformed' : 'infoSave'] : ['submit-cancel', 'infoDuplicate']
        }
        displayedComponent={displayedComponent}
        setDisplayedComponent={setDisplayedComponent}
        displayedComponentMode={displayedComponentMode}
        setDisplayedComponentMode={setDisplayedComponentMode}
        handleSave={() => setRunSave(true)}
        userChangesPresent={userChangesPresent}
        afterSaveGoTo={displayedComponent === 'duplicate' ? 'table' : 'column'} // for the cancel button
        setTransformationParams={setTransformationParams}
        transformationParams={transformationParams}
        handleUndoTransform={handleUndoTransform}
      />
      <Grid
        account={account} // to get current account status (after loading) and react to evtl. errors
        setAccount={setAccount} // close AccountDetails after saving
        data={gridData}
        gridLayout={gridLayout}
        prepAndValidateOutputData={prepAndValidateOutputData}
        postCategoryItems={customPostCategoryItems} // this is DISPATCHED in the Grid component; working assumption: thunk middleware will wave it though?
        tableState={tableState}
        displayedComponent={displayedComponent}
        setDisplayedComponent={setDisplayedComponent} // to go back to table after loading is finished,
        userChangesPresent={userChangesPresent} // to prevent closing AccountDetails without saving
        setUserChangesPresent={setUserChangesPresent} // to prevent closing AccountDetails without saving
        runSave={runSave}
        setRunSave={setRunSave}
        afterSaveGoTo={displayedComponent === 'duplicate' ? 'table' : 'column'} // after previewer we go back to column matcher to see our edited data
        postActionHistory={displayedComponent === 'duplicate' ? null : postActionHistory}
        customFilter={displayedComponent === 'duplicate' ? null : true} // custom filter for Grid in Previewer mode
        transformationParams={transformationParams} // pass parsing masks
        setTransformationParams={setTransformationParams} // sets commands when user clicks stuff in Grid
        scrollToRow={scrollToRow}
        setScrollToRow={setScrollToRow}
      />
    </>
  );
}
Previewer.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  displayedComponent: PropTypes.string.isRequired,
  setDisplayedComponent: PropTypes.func.isRequired,
  displayedComponentMode: PropTypes.string.isRequired,
  setDisplayedComponentMode: PropTypes.func.isRequired,
  currentFileIngested: PropTypes.arrayOf(PropTypes.any),
  currentFilePretransformed: PropTypes.arrayOf(PropTypes.any),
  updateCurrentFilePretransformed: PropTypes.func,
  currentFileTransformed: PropTypes.arrayOf(PropTypes.any),
  currentFileName: PropTypes.string.isRequired,
  setTransformationParams: PropTypes.func,
  transformationParams: PropTypes.objectOf(PropTypes.any),
  fileProcessingComplete: PropTypes.func.isRequired,
  userChangesPresent: PropTypes.bool.isRequired,
  setUserChangesPresent: PropTypes.func.isRequired,
  tableState: PropTypes.objectOf(PropTypes.any).isRequired,
  setAccount: PropTypes.func.isRequired,
  pretransformationApplied: PropTypes.bool,
  setPretransformationApplied: PropTypes.func,
  scrollToRow: PropTypes.number,
  setScrollToRow: PropTypes.func,
};
Previewer.defaultProps = {
  currentFileIngested: [],
  currentFilePretransformed: [],
  updateCurrentFilePretransformed: () => {},
  currentFileTransformed: [],
  setTransformationParams: () => {},
  transformationParams: {},
  pretransformationApplied: null,
  setPretransformationApplied: () => {},
  scrollToRow: null,
  setScrollToRow: () => {},
};
