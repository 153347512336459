/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import jspreadsheet from '@monestry-dev/jspreadsheet-ce';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import applyFilters from './gridApplyFilters';
import addFormattingToColumns from './gridColumnFormatter';
import { setMessage, setAlert } from '../../redux/actions/message';
import TourWrapperInner from '../../elements/TourWrapper';
import executeFormulaPatch from './jspreadsheetExecuteFormulaPatch';
import GridFilterDialog from './GridFilterDialog';
import getPurchaseValues from './gridMetalsGetPurchaseValue';
import addFormulaToCommands from './gridAddFormulaToCommands';

import '../../css/jspreadsheet.css';

import ContextMenu from './GridContextMenu';
import { putAccount } from '../../redux/reducers/data';

dayjs.extend(utc);

const debugLevel = process.env.MDEBUG || 3;

// receives data as array of objects, handlesSave from CategoryWrapper, tableState,
export default function Grid({
  data,
  gridLayout, // may be null if we do not use headings and formatting (e.g. in import > Previewer)
  prepAndValidateOutputData,
  postCategoryItems,
  tableState,
  account,
  setAccount,
  displayedComponent,
  setDisplayedComponent,
  userChangesPresent,
  setUserChangesPresent,
  runSave,
  setRunSave,
  customDropdownSources,
  customOnChange,
  saveDialogPromiseCallback,
  scrollToRow, // pass a row number to make Grid scroll to that row on initialisation
  setScrollToRow, // pass a function to set the scrollToRow state
  afterSaveGoTo, // normally after saving this will setDisplayedComponent to 'table', but you can pass a different value here
  customFilter, // default false, if set, Grid will use a custom filter function
  transformationParams, // default {}, contains parsing masks and headersInRow -- if they ares set, Grid will use the masks to parse the data before saving; format: { decimalChar: ',', dateFormat: 'DD.MM.YYYY' }
  // headersInRow will be passed to the GridFilterDialog and used to add headers to filtered dataset
  setTransformationParams, // Grid will update the transformationParams.commands object with the new values
}) {
  function getColIdxFromLayout(columnId) {
    if (!gridLayout) return null;
    return gridLayout.findIndex((item) => item.id === columnId);
  }

  // adds the command to transformationParams.commands
  // this function is duplicated in GridContextMenu
  function addCommandToHistory(command, args) {
    setTransformationParams((prev) => {
      // if there is already a formula command for a given column, remove it before adding a new one
      // formula's arguments are [columnIndex, parsedFormula]
      const newCommands = [...prev.commands.filter((c) => !(c.command === 'formula' && c.args[0] === args[0])), { command, args }];
      return { ...prev, commands: newCommands };
    });
  }

  // ---------------------------------------------------
  // SELECTORS + STATE
  // ---------------------------------------------------

  const { i18n, t } = useTranslation('app', { keyPrefix: 'accountDetails' });
  const dispatch = useDispatch();

  const dashboardMode = useSelector((state) => state.simulation.dashboardMode);
  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency);

  const [dirtyRows, setDirtyRows] = useState(new Set()); // holds the list of rows that have been changed by the user
  const filterState = useRef(transformationParams?.filters || []); // holds the filter state for each column of the data (changes need to be accessible from jspreadsheet)

  // holds the state of the context menu
  const [contextMenu, setContextMenu] = useState({
    visible: false,
    x: 20,
    y: 20,
    clickedRow: 1,
    clickedCol: 1,
  }); // (x,y) are coordinates of the cursor when the right click was triggered (relative to the jSpreadsheet component!)

  const [displaySpinner, setDisplaySpinner] = useState(false); // shows the spinner when user is committing changes

  // set up refs (used to anchor and exchange information with the jspreadsheet component)
  const jRef = useRef(null); // holds the jspreadsheet component
  const rootElement = useRef(null);
  const contextMenuRef = useRef(); // it cannot access the current state and keeps using the initial value
  contextMenuRef.current = contextMenu; // make sure the ref stays equal to the updated state
  const dirtyRowsRef = useRef(); // it cannot access the current state and keeps using the initial value
  dirtyRowsRef.current = dirtyRows; // make sure the ref stays equal to the updated state
  // because the jSpreadsheet component is activated directly in DOM and not through React

  // ---------------------------------------------------
  // EVENT HANDLERS FOR EVENTS OUT OF JSPREADSHEET
  // ---------------------------------------------------

  function handleContextMenu(obj, x, y, e, items, section) {
    if (process.env.REACT_APP_MDEBUG > 2) console.log('triggering ContextMenu handler', obj, x, y, e, items, section);
    // context menu is defined in React (not in jSpreadsheet)
    // the following jSpreadsheet functionality updates contextMenu state object with the coordinates of the context menu
    // and displays it

    // if clientY + 350 is greater than the height of the window, the context menu will be displayed above the cursor
    // if clientY - 350 is less than 0, the context menu will be displayed below the cursor
    const windowHeight = window.innerHeight;
    const yCoordinate = e.clientY > windowHeight - 350 ? e.clientY - 350 : e.clientY;

    setContextMenu({
      ...contextMenuRef.current,
      visible: true,
      x: e.clientX + 4,
      y: yCoordinate + 4,
      clickedRow: y,
      clickedCol: x,
    });

    return false;
  }

  const obj = jRef.current && jRef.current.jspreadsheet;
  const options = obj?.options || {};

  function handleOnSelection(element, x, y) {
    if (x === undefined || y === undefined) return;
    if (jRef.current) {
      setContextMenu({
        ...contextMenuRef.current,
        selectedColumns: jRef.current.jexcel.getSelectedColumns(true),
        selectionHTML: Array.from(document.getElementsByClassName('highlight')).map((el) => el.innerHTML),
      });
      // if cell is in a dropdown column and if the cell is empty, then automatically open editor
      const columnName = jspreadsheet.getColumnNameFromId([x, y]);
      // eslint-disable-next-line no-use-before-define
      if (jRef.current.jspreadsheet.options.columns && jRef.current.jspreadsheet.options.columns[x]?.type === 'dropdown' && jRef.current.jexcel.getValue(columnName) === '') {
        const cell = jRef.current.jexcel.getCell(columnName);
        // mouseup event after the dropdown is opened cancels the dropdown
        // so delay the opening of the editor by 125ms to allow that event to pass
        setTimeout(() => jRef.current.jexcel.openEditor(cell), 125);
      }
    }
  }

  // does not run on insert / remove column / row, just value changes
  function handleChange(instance, cell, x, y, value) {
    // cell returns outerHTML of the cell, value returns new value
    const cellName = jspreadsheet.getColumnNameFromId([x, y]);
    jRef.current.jspreadsheet.setStyle(cellName, 'background-color', 'white'); // if it is just "set yellow", it switches yellow on and off
    jRef.current.jspreadsheet.setStyle(cellName, 'background-color', 'rgb(254 249 195 / var(--tw-bg-opacity))');
    setUserChangesPresent(true);

    const updatedDirtyRows = new Set(dirtyRowsRef.current);
    updatedDirtyRows.add(y);
    setDirtyRows(updatedDirtyRows);

    // execute any custom onChange function passed from Wrapper parent components
    if (customOnChange) customOnChange(instance, cell, x, y, value);

    // if there are transformationParams and if this is a formula, run the addFormula function to check if we need to add the formula
    if (Object.keys(transformationParams).length > 0 && value.startsWith('=')) {
      console.log('DEBUG handleChange > formula detected', value);
      const formula = addFormulaToCommands(jRef.current.jspreadsheet, x, transformationParams?.headersInRow);
      // ↑↑ this will only return a string if the formula is present in 90% of the rows below the headers
      if (formula) {
        console.log('DEBUG handleChange > formula added to commands', formula);
        addCommandToHistory('formula', [x, formula]);
      }
    }
  }

  // in all fairnes, this isn't as much as handleLoad as it is handleSetData (it runs on every setData)
  // CAUTION: if an action is to happen only once during intialisation, put it in the useEffect where jspreadsheet is initialised
  // display incoming errors: if property errorId is truthy, the comment on the corresponding cell in jRef is set to the translated error message
  function handleSetData() {
    console.log('DEBUG handleLoad is running');
    // if errorId is truthy (not undefined, '' or null) takes errorIds from column  of the dataset,
    // translates them and puts them into the cell comments of the second column (first may be date / calendar)
    data.forEach((item, row) => {
      if (item.errorId) {
        jRef.current.jexcel.setComments(jspreadsheet.helpers.getColumnNameFromCoords(1, row));
      } // this goes into column 2 only!
    });
  }

  function handleDeleteRow(_obj, rowNo, numberOfRows, elements, deletedData) {
    // handle following bug through workaround:
    // right-click a cell in any row (which is not the bottom row) and select delete row from context menu
    // immediatelly right-click the same cell again (new row is there already) to see context menu -- does not work
    // workaround: click anywhere else in spreadsheet after delete
    if (!userChangesPresent) setUserChangesPresent(true); // this capturest the deletion of a row by using Delete key instead of context menu
    jRef.current.jexcel.updateSelectionFromCoords(0, rowNo - 1 < 0 ? 0 : rowNo - 1);
  }

  function handlePaste(object, pastedData) {
    // on every paste command (via context menu or keyboard shortcut)
    jRef.current.jexcel.getSelectedColumns(true).forEach((col) => {
      jRef.current.jexcel.getSelectedRows(true).forEach((row) => {
        const cellName = jspreadsheet.helpers.getColumnNameFromCoords(col, row);
        jRef.current.jexcel.setStyle(cellName, 'background-color', 'white'); // if it is just "set yellow", it switches yellow on and off
        jRef.current.jexcel.setStyle(cellName, 'background-color', 'rgb(254 249 195 / var(--tw-bg-opacity))');
        if (!userChangesPresent) setUserChangesPresent(true);
      });
    });
  }

  // -----------------------------------------------------------------------
  // ESCAPE KEY HANDLER
  // -----------------------------------------------------------------------

  // close context menu when Esc is pressed anywhere in the document
  const handleEscPress = useCallback((event) => {
    if (contextMenuRef.current.visible && event.key === 'Escape') {
      event.stopImmediatePropagation();
      setContextMenu({ ...contextMenuRef.current, visible: false });
    }
  }, []);

  // intialise: add event listener for Esc key; reset userChangesPresent; remove the default alert function (because it got triggered when deleting a row using Del)
  useEffect(() => {
    window.confirm = () => true;
    // normally window.confirm displays an OK / Cancel dialog and is used in jSpreadsheet to display among others "are you sure you want to delete this row", which we don't really want
    // this has been overridden here to return true immediatelly; handleDeleteRow will then set userChangesPresent to true

    document.addEventListener('keydown', handleEscPress, false);
    setUserChangesPresent(false);

    return () => {
      document.removeEventListener('keydown', handleEscPress, false);
    };
  }, []);

  // -----------------------------------------------------------------------
  // HANDLE REFRESH DATA EVENT
  // -----------------------------------------------------------------------

  // in Previewer pretransformations are applied automatically, but the user can undo them
  // to do that they press a button in ButtonBar which runs a function in Previewer (passed via props)
  // that function needs to trigger a refresh of the data in Grid by sending event 'gridReloadData' and the ingested data as payload
  // this sets up the event listener + cleanup
  useEffect(() => {
    const handleReloadData = (e) => {
      if (jRef.current && jRef.current.jexcel) {
        console.log('DEBUG handleReloadData is running', e.detail);
        jRef.current.jexcel.setData(e.detail);
      }
    };

    window.addEventListener('gridReloadData', handleReloadData);

    return () => {
      window.removeEventListener('gridReloadData', handleReloadData);
    };
  }, []);

  // -----------------------------------------------------------------------
  // ADD / REMOVE FILTERS TO / FROM COLUMNS
  // -----------------------------------------------------------------------

  function handleOnDeleteColumn(el, colIdx, numberCols) {
    // remove column from filterState
    filterState.current.splice(colIdx, numberCols);
  }

  // function runs when user finishes the filter dialog below and makes changes to filters
  function updateFilterState(tdColumnIndex, filterValue) {
    // update filterState in the ref
    filterState.current[tdColumnIndex - 1] = filterValue; // this is an array of filter objects

    // adjust appearance of the filter button in the header
    const headerCells = jRef.current.querySelectorAll('thead td');
    headerCells.forEach((td, index) => {
      if (index === tdColumnIndex) {
        const svg = td.querySelector('.grid-filter-icon');
        if (svg) {
          svg.setAttribute('fill', filterValue.length > 0 ? 'rgb(148,163,184)' : 'none');
        }
      }
    });

    // update data to be displayed in the spreadsheet
    if (jRef.current && jRef.current.jexcel) {
      const unfilteredRowIndices = applyFilters(
        jRef.current.jexcel.getData(),
        filterState.current,
        { decimalChar: transformationParams?.decimalChar, dateFormat: transformationParams?.dateFormat },
        transformationParams?.headersInRow,
      );
      console.log('DEBUG updateFilterState > unfilteredRowIndices', unfilteredRowIndices);
      // go through all rows and reset / reapply filters
      jRef.current.jexcel.rows.forEach((row, rowIndex) => {
        if (!unfilteredRowIndices.includes(rowIndex)) jRef.current.jexcel.rows[rowIndex].style.display = 'none';
        else jRef.current.jexcel.rows[rowIndex].style.display = '';
      });
      // update filters in transformation params for saving
      setTransformationParams((prev) => ({ ...prev, filters: filterState.current }));
    }
  }

  // update spreadsheet data when transformation parameters change
  useEffect(() => {
    if (jRef.current && jRef.current.jexcel) {
      const unfilteredRowIndices = applyFilters(
        jRef.current.jexcel.getData(),
        filterState.current,
        { decimalChar: transformationParams?.decimalChar, dateFormat: transformationParams?.dateFormat },
        transformationParams?.headersInRow,
      );
      // go through all rows and reset / reapply filters
      console.log('DEBUG useEffect > unfilteredRowIndices', unfilteredRowIndices);
      jRef.current.jexcel.rows.forEach((row, rowIndex) => {
        if (!unfilteredRowIndices.includes(rowIndex)) jRef.current.jexcel.rows[rowIndex].style.display = 'none';
        else jRef.current.jexcel.rows[rowIndex].style.display = '';
      });
    }
  }, [transformationParams]);

  // function is attached to each filter button in the column header and will open a dialog to set the filter
  function handleFilterButton(e) {
    // let's figure out which column is this
    // get the parent element of the button parent of the svg
    const grandparent = e.target.closest('button').parentElement;

    // Find the index of the grandparent element among its siblings
    let tdIndex = 0;
    let sibling = grandparent;
    // eslint-disable-next-line no-cond-assign
    while ((sibling = sibling.previousElementSibling) != null) {
      tdIndex += 1;
    }

    // attention: index is the index of column (td element) under thead --> row number column is 0, first data column is 1
    // filterState has columns from data, first data column is 0

    // now that we have the correct index, let us bring up the dialog and update the state
    window.dispatchEvent(
      new CustomEvent('setDialog', {
        detail: {
          Component: GridFilterDialog,
          props: {
            currentRules: filterState.current[tdIndex - 1],
            callback: (value) => {
              updateFilterState(tdIndex, value.rules);
              window.dispatchEvent(new CustomEvent('setDialog', {}));
            },
            callbackCancel: () => window.dispatchEvent(new CustomEvent('setDialog', {})),
          },
        },
      }),
    );
  }

  // this function adds the filter button to the column headers and turns it on if there is a filter set for that column index
  // headerCells need to be HTML elements (typically the td elements of the thead, one or many)
  function addFilterButtonToHeaderCells(headerCells) {
    if (!headerCells) return;

    headerCells.forEach((td, index) => {
      const color = filterState.current[index] ? 'rgb(148, 163, 184)' : 'none'; // if there is a filter set for this column, set the filter button on

      if (index === 0) return; // skip the first column (id)

      let button = td.querySelector('.grid-filter-button');
      if (!button) {
        // Create the button and SVG using insertAdjacentHTML
        const buttonHTML = `
          <button class="grid-filter-button" style="position: absolute; top: 11px; right: 7px; background: transparent; border: none; padding: 0; cursor: pointer;">
            <svg class="grid-filter-icon" fill="${color}" viewBox="0 0 24 24" stroke-width="1.5" stroke="rgb(148, 163, 184)" style="width: 15px; height: 15px;">
              <path stroke-linecap="round" stroke-linejoin="round" d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 0 1-.659 1.591l-5.432 5.432a2.25 2.25 0 0 0-.659 1.591v2.927a2.25 2.25 0 0 1-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 0 0-.659-1.591L3.659 7.409A2.25 2.25 0 0 1 3 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0 1 12 3Z"/>
            </svg>
          </button>`;
        // Insert the button with the SVG into the <td>
        td.insertAdjacentHTML('beforeend', buttonHTML);

        // Attach the onClick handler to the newly added button
        button = td.querySelector('.grid-filter-button');
        button.onclick = handleFilterButton;
      } else {
        // Update the color of the SVG if the button already exists
        const svg = button.querySelector('.grid-filter-icon');
        if (svg) {
          svg.setAttribute('fill', color);
        }
      }
    });
  }

  function handleOnInsertColumn(el, colIdx, numberCols) {
    console.log('handleOnInsertColumn', el, colIdx, numberCols);
    // Add small div to the newly inserted column's header
    const headerCells = jRef.current.querySelectorAll(`thead td:nth-child(${colIdx + 1})`);
    addFilterButtonToHeaderCells(headerCells);

    // insert a new column into the filterState
    filterState.current.splice(colIdx, 0, null);
  }

  // -----------------------------------------------------------------------
  // INITIALISE JSPREADSHEET
  // -----------------------------------------------------------------------

  // initialise jspreadsheet at the jRef ref
  useEffect(() => {
    // standard set of options to be partially overwritten by whatever is needed for different categories (params)
    const columnDefinitionsFromGridLayout = addFormattingToColumns(gridLayout, customDropdownSources, tableState, baseCurrency, i18n, t, account?.currency);

    // get the anount of columns from gridLayout / addFormattingToColumns; if there is no gridLayout / addFormattingToColumns, get the amount of columns from the data
    const columnCount = columnDefinitionsFromGridLayout ? columnDefinitionsFromGridLayout.length : data.reduce((acc, row) => Math.max(acc, Object.keys(row).length), 0);

    const prepOptions = {
      data, // array of objects is ok as input
      copyCompatibility: true, // getData returns values instead of formulas
      editable: dashboardMode !== 'projects',
      minDimensions: [displayedComponent === 'previewer' ? columnCount + 2 : columnCount, 20], // if this is previewer, give the user some extra column space
      minSpareRows: 1,
      tableHeight: '100%',
      tableWidth: '100%',
      tableOverflow: true,
      columnDrag: displayedComponent === 'previewer',
      autoIncrement: true,
      allowInsertColumn: displayedComponent === 'previewer',
      allowManualInsertColumn: displayedComponent === 'previewer',
      allowDeleteColumn: displayedComponent === 'previewer',
      allowRenameColumn: false,
      contextMenu: handleContextMenu,
      onselection: handleOnSelection,
      onchange: handleChange,
      onload: handleSetData, // runs after every setData
      onpaste: handlePaste,
      ondeleterow: handleDeleteRow,
      oninsertcolumn: handleOnInsertColumn,
      ondeletecolumn: handleOnDeleteColumn,
    };

    if (columnDefinitionsFromGridLayout) {
      prepOptions.columns = columnDefinitionsFromGridLayout;
    }

    // add column type number and a decimal point to all columns in an attempt to make parsing automatic
    // (only if there is no gridLayout, at the moment it applies to previewer)
    if (!columnDefinitionsFromGridLayout) {
      prepOptions.columns = Array.from({ length: columnCount }, () => ({
        type: 'number',
        decimal: transformationParams?.decimalChar || ',',
      }));
    }

    // in project mode calculate which rows are simulated and format them violet
    // expected format [ { A1: 'background-color: orange; ' }, ... ]
    const letters = [];
    let cursor = 0;
    if (prepOptions.columns) {
      while (cursor < prepOptions.columns.length) {
        if (prepOptions.columns[cursor].type !== 'hidden') letters.push(String.fromCharCode(65 + cursor)); // generates an array of capital letters starting with ASCII code 65 (A)
        cursor += 1;
      }
      const style = (data || []).reduce(
        // eslint-disable-next-line max-len
        (acc, row, index) => (row.isSimulated ? { ...acc, ...letters.reduce((prev, curr) => ({ ...prev, [`${curr}${index + 1}`]: 'background-color: rgb(248 240 250); font-style: italic; ' }), {}) } : { ...acc }),
        {},
      );

      prepOptions.style = style;
    }

    if (!jRef.current.jspreadsheet && prepOptions.data) {
      // ---------------------------------------------------
      // INITIALISE THE SPREADSHEET
      // ---------------------------------------------------
      // if there are fomulas in the data (e.g. in Previewer), we need to initialise the spreadsheet AND APPLY THE PATCH before importing data (otherwise formulas won't work)
      const { data: withheldData, ...stageOneOptions } = prepOptions;
      jspreadsheet(jRef.current, stageOneOptions);

      // executeFormula uses eval -- copied that function locally and patched it unitl there is a permanent fix
      // @monestry-dev/jspreadsheet-ce@4.2.2 has a partial fix (fixes the usage of eval in executeFormula), but the problem still persists
      // (see jspreadsheetFormulaPatch to get you started)
      jRef.current.jspreadsheet.executeFormula = function customExecuteFormula(arg1, arg2, arg3) {
        return executeFormulaPatch(arg1, arg2, arg3, jRef.current.jspreadsheet);
      };
      const tmp = jRef.current.jexcel.setData(withheldData);
      console.log('Initialising jspreadsheet with options:', stageOneOptions, 'and data', withheldData, tmp);

      // ---------------------------------------------------
      // PERFORM ADDITIONAL ACTIONS AFTER INITIALISATION
      // ---------------------------------------------------

      // if custom filter is enabled, we need to initalise it (from saved data or empty)
      if (customFilter) {
        // Add small divs to each <td> under <thead> at initialization
        const headerCells = jRef.current.querySelectorAll('thead td');
        addFilterButtonToHeaderCells(headerCells);

        // initialise filterState
        if (!filterState.current) {
          filterState.current = new Array(columnCount).fill(null);
        } else if (filterState.current.length < columnCount) {
          // check if we have enough columns in filterState and add if necessary
          filterState.current = filterState.current.concat(new Array(columnCount - filterState.current.length).fill(null));
        }

        // apply filters to the data
        const unfilteredRowIndices = applyFilters(
          data,
          filterState.current,
          { decimalChar: transformationParams?.decimalChar, dateFormat: transformationParams?.dateFormat },
          transformationParams?.headersInRow,
        );
        console.log('DEBUG init > unfilteredRowIndices', unfilteredRowIndices);
        // go through all rows and reset / reapply filters
        jRef.current.jexcel.rows.forEach((row, rowIndex) => {
          if (!unfilteredRowIndices.includes(rowIndex)) jRef.current.jexcel.rows[rowIndex].style.display = 'none';
          else jRef.current.jexcel.rows[rowIndex].style.display = '';
        });
      }

      // add tooltips to the headers
      // it has the title property (set to column name), so we will just overwrite it with the tooltip
      const headers = document.querySelectorAll('.jexcel thead tr td');
      // console.log('DEBUG headers', headers);
      // const tooltips = ['Tip for A', 'Tip for B', 'Tip for C'];

      headers.forEach((header, index) => {
        // index starts at 0, which is the row number column, so we need to skip it
        if (index === 0) return;
        // check if there is a tooltip; if there isn't, don't change anything
        if (prepOptions.columns && prepOptions.columns[index - 1]?.tooltip) {
          header.setAttribute('title', prepOptions.columns[index - 1].tooltip);
        }
      });

      // inherit sorting order from table (do not do that when there is no gridLayout, i.e. when we are displaying raw data e.g. for import)
      if (gridLayout) jRef.current.jexcel.orderBy(getColIdxFromLayout(tableState.sortBy), Number(!tableState.sortDirectionAsc)); // true (1) means descending in this function

      // check if grid col widths are saved in account.tags.gridColWidths and apply them
      if (gridLayout && account.tags?.gridColWidths) {
        jRef.current.jspreadsheet.options.columns.forEach((def, index) => {
          if (account.tags.gridColWidths[index]) {
            jRef.current.jspreadsheet.setWidth(index, account.tags.gridColWidths[index]);
          }
        });
      }

      // if we are in raw data mode, set col width to 100
      if (!gridLayout) {
        for (let i = 0; i < jRef.current.jspreadsheet.options.minDimensions[0]; i += 1) {
          jRef.current.jspreadsheet.setWidth(i, 100); // Set the width for each column
        }
      }

      // additionally add an event listener for jexcel_container because the keydown event originates from the jexcel_container
      // so it will be triggered BEFORE the document-level JSuites element which prevents other events from being triggered
      document.getElementsByClassName('jexcel_container')[0].addEventListener('keydown', handleEscPress, false);
    }

    return () => {
      document.getElementsByClassName('jexcel_container')[0]?.removeEventListener('keydown', handleEscPress, false);
    };
  }, [data]);

  // -----------------------------------------------------------------------
  // HANDLE SAVE
  // -----------------------------------------------------------------------

  // gets widths of all displayed columns and saves them in the tableState
  function saveColWidths() {
    const rows = jRef.current.jexcel.getRowData(0); // this assumes that jspreadsheet makes all rows are the same length
    const gridColWidths = [];
    rows.forEach((row, idx) => {
      const width = jRef.current.jexcel.getWidth(idx);
      gridColWidths.push(Number(width));
    });
    dispatch(putAccount({ data: { ...account, tags: { ...account.tags, gridColWidths } }, category: account.category }));
  }

  // does some internal validation and then calls handleSubmit
  // if there is responseDialog, it means that the user was asked to provide some additional data (like asset mapping for Cryptos)
  // responseDialog is an array containing the same number of items (rows) as the outputData, but only a subset of fields,
  // for example only { assetId, displaySymbol, providerAssetId } for Cryptos, so it needs to be spread over the outputData
  function prepAndValidateOutputDataWrapper(responseDialog = undefined) {
    // get data from the spreadsheet object and send them on their merry way
    // apply filters if existing
    const unfilteredRowIndices = applyFilters(
      jRef.current.jspreadsheet.getData(),
      filterState.current,
      { decimalChar: transformationParams?.decimalChar, dateFormat: transformationParams?.dateFormat },
      transformationParams?.headersInRow,
    );

    // apply filters to output data
    const outputData = jRef.current.jspreadsheet.getData().filter((row, index) => unfilteredRowIndices.includes(index));

    // now run the prepAndValidateOutputData function from CategoryWrapper
    // (it will run general transformations and category-specific ones from accountDetails/transactions or /quotes)
    // send gridLayout back to CategoryWrapper, because it is it's determined in its child, so CategoryWrapper doesn't know about it
    const prepAndValidateResult = prepAndValidateOutputData(outputData, gridLayout, dirtyRows, responseDialog);

    if (debugLevel > 2) console.log('Grid > prepAndValidateOutputDataWrapper: prepAndValidateResult', prepAndValidateResult);

    if (prepAndValidateResult.status === 'error') {
      setRunSave(false);
      const validationErrors = prepAndValidateResult.errors;

      // clear all old errors (comments)
      // TODO if previous data had more rows, the comments will not get cleaned
      try {
        outputData.forEach((row, rowIdx) => {
          row.forEach((cell, colIdx) => {
            // clear all comments
            jRef.current.jexcel.setComments(jspreadsheet.helpers.getColumnNameFromCoords(colIdx, rowIdx), '');
          });
        });

        // for each error get row and column and put error in comments
        // the thrown error is error message from yup and path to error like [row].fieldName (e.g. This is an error, [0].otherParty)
        console.log('validationErrors', validationErrors);

        validationErrors.forEach((err) => {
          console.log('Validation error:', err.message);

          const rowNo = err.path.split('.')[0].replace(/\D+/g, '') || 0;
          const columnNo = getColIdxFromLayout(err.path.split('.')[1]);

          console.log('rowNo', rowNo, 'columnNo', columnNo);
          console.info('Row causing the error:', prepAndValidateResult[rowNo]);

          // if column has been found in Grid (is a number and is greater or equal 0) and that column is visible, then set the comment there
          // otherwise set it in the first visible column
          if ((!!columnNo || columnNo === 0) && columnNo >= 0 && options.columns[columnNo]?.type !== 'hidden') {
            jRef.current.jexcel.setComments(jspreadsheet.helpers.getColumnNameFromCoords(columnNo, rowNo), err.message);
          } else {
            console.info('Column for the above error is not displayed in Grid');
            // find first column which is not hidden
            const firstNotHiddenColumnNo = options.columns.findIndex((c) => c.type !== 'hidden');
            jRef.current.jexcel.setComments(jspreadsheet.helpers.getColumnNameFromCoords(firstNotHiddenColumnNo, rowNo), err.message);
          }
        });
        // throw new Error('localValidationFailed');
        // show message to user
        dispatch(setMessage('checkYourData'));
        setDisplaySpinner(false);
      } catch (err) {
        throw new Error(`Grid > prepAndValidateOutputDataWrapper: unhandled error ${err.message}`, err);
      }
    }

    if (prepAndValidateResult.status === 'success') {
      return prepAndValidateResult; // { status, data }
    }
    return { status: 'error' };
  }

  // this gets called a little later, read on...
  async function handleSubmit(validatedData) {
    console.log('Grid > starting handleSubmit for data', validatedData);
    try {
      setDisplaySpinner(true);
      // with the exception of Previewer, which is returning data to ImportFile state, all other use cases require sending data to backend
      // Previewer has a simple function that sets the state and does not use redux
      const response = displayedComponent !== 'previewer' ? await dispatch(postCategoryItems(validatedData, account)) : postCategoryItems(validatedData);

      // save user column widths if we are not in Previewer (we save them to account.tags.gridColWidths and they will apply next time we open the Grid)
      // for Previewer we don't do that for now, we want imports to run more or less automatically
      if (displayedComponent !== 'previewer') saveColWidths();

      setDisplaySpinner(false);
      console.log('Grid: postData response', response);
      // response.meta.requestStatus is used in quotes, it is the "redux-toolkit" way of doing things
      if (response?.status === 'success' || response?.meta?.requestStatus === 'fulfilled') {
        setUserChangesPresent(false);
        setDisplayedComponent(afterSaveGoTo);
        return;
      }
      dispatch(setMessage('dataUpdateProblems')); // if we got here, it means that was not a 'success' response
      console.error('Grid: handleSubmit ran aground.');
    } catch (err) {
      console.error('Grid: error in dispatch', err);
      setDisplaySpinner(false);
      dispatch(setMessage('dataUpdateProblems'));
    }
  }

  // passes jRef and gridLayout to the dialog promise callback
  function createDialogPromise() {
    return new Promise((resolve, reject) => {
      saveDialogPromiseCallback(resolve, reject, jRef, gridLayout);
    });
  }

  // ---------------------------------------------------
  // SUBSCRIBE TO runSave EVENT FROM BUTTON_BAR
  // ---------------------------------------------------

  // ButtonBar will set the runSave flag to true when the user clicks Save
  useEffect(() => {
    if (debugLevel > 2) console.log('Grid > runSave signal received', runSave, 'userChangesPreset', userChangesPresent, 'displayedComponent', displayedComponent);

    // if the user has not made any changes, save and close (exception: for displayedComponent: 'duplicate', we accept also no changes here)
    if (runSave === true && !userChangesPresent && !['duplicate', 'previewer'].includes(displayedComponent)) {
      setRunSave(false); // reset the signal
      setDisplayedComponent('table');
    }

    if (runSave === true && (userChangesPresent || ['duplicate', 'previewer'].includes(displayedComponent))) {
      // if we need to ask user something before validating and saving data, this is where it happens
      // this promise shows a dialog and sends back updated data when user clicks ok
      // if there is no saveDialogPromiseCallback, the default function from propTypes will resolve to null (responseDialog will be null)
      createDialogPromise().then(
        (responseDialog) => {
          // if dialog is not necessary for this workflow, responseDialog will be null;
          // otherwise it will be an array of the same length as the grid data with objects { assetId, displaySymbol, providerAssetId }
          // because responseDialog is just a subset of the data, so we will pass it to prepAndValidate...
          // to be spread over the converted all data object

          // get data from the spreadsheet object, transform back to their original structure and validate them
          // if prepAndValidate... receives an non-empty argument, it knows it has to spread it over the data
          const resultsPrepAndValidate = responseDialog ? prepAndValidateOutputDataWrapper(responseDialog) : prepAndValidateOutputDataWrapper();
          if (debugLevel > 2) console.log('Grid > runSave: resultsPrepAndValidate', resultsPrepAndValidate);
          setRunSave(false); // reset the signal

          if (resultsPrepAndValidate?.status === 'error') {
            dispatch(setMessage('checkYourData'));
          }
          if (resultsPrepAndValidate?.status === 'success') {
            dispatch(setAlert('aboutToSave', account.id, () => handleSubmit(resultsPrepAndValidate.data))); // if the user presses OK, it will start handleSubmit
          }
        },
        // handle error from waiting for the dialog
        (errorDialog) => {
          console.error('Grid > runSave: error in dialog promise', errorDialog);
          dispatch(setMessage('checkYourData'));
          setRunSave(false);
        },
      );
    }
  }, [runSave]);

  // ---------------------------------------------------
  // (METALS ONLY) HANDLE GET PURCHASE VALUE
  // ---------------------------------------------------

  useEffect(() => {
    if (account.category === 'metals') window.addEventListener('gridGetPurchaseValues', getPurchaseValues);

    return () => {
      if (account.category === 'metals') window.removeEventListener('gridGetPurchaseValues', getPurchaseValues);
    };
  }, [data]);

  // ---------------------------------------------------
  // HANDLE SCROLL TO ROW
  // ---------------------------------------------------

  useEffect(() => {
    if (jRef.current && scrollToRow) {
      const cell1 = jRef.current.jexcel.getCell(`A${scrollToRow + 1}`);
      if (cell1) {
        cell1.scrollIntoView({ behavior: 'smooth', block: 'center' });
        jRef.current.jexcel.updateSelection(cell1, cell1, true);
      }
      setScrollToRow(null);
    }
  }, [scrollToRow]);

  return (
    <>
      <section className="pt-2 grow flex flex-col overflow-hidden w-full relative" aria-label="context menu" id="contextmenu">
        {displaySpinner && (
          <div className="absolute bg-gray-50/50 transition duration-700 w-full h-full z-30" id="synchronisingspinner">
            <div className="flex h-full items-center justify-center">
              <button
                // eslint-disable-next-line max-len
                className="-mt-2 inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-md text-white bg-brandBlue-500 hover:bg-brandBlue-400 transition ease-in-out duration-150 cursor-not-allowed"
                type="button"
              >
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white text-base" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                </svg>
                {t('synchronisingChanges')}
              </button>
            </div>
          </div>
        )}
        {/* closes the context menu when clicked everywhere but on it */}
        <div
          className="z-20 h-full relative inline-block text-left"
          onClick={(e) => {
            if (contextMenu.visible === true) setContextMenu({ ...contextMenuRef.current, visible: false });
          }}
          ref={rootElement}
          role="button"
          tabIndex="0"
        >
          {/* JSPREADSHEET IS ATTACHED HERE */}
          <div className="h-full" id="account-details-grid-spreadsheet">
            <div className="h-full w-full" ref={jRef} />
            <br />
          </div>
          {/* CONTEXT MENU */}
          <ContextMenu
            obj={obj}
            jRef={jRef}
            contextMenu={contextMenu}
            setContextMenu={setContextMenu}
            userChangesPresent={userChangesPresent}
            setUserChangesPresent={setUserChangesPresent}
            gridLayout={gridLayout}
            accountName={account.name}
            enableAddDeleteColumns={displayedComponent === 'previewer'}
            setTransformationParams={setTransformationParams}
          />
        </div>
      </section>
      <TourWrapperInner componentName="accountDetailsGrid" />
    </>
  );
}

Grid.propTypes = {
  tableState: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  gridLayout: PropTypes.array,
  prepAndValidateOutputData: PropTypes.func.isRequired,
  postCategoryItems: PropTypes.func.isRequired,
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  setAccount: PropTypes.func.isRequired,
  displayedComponent: PropTypes.string.isRequired,
  setDisplayedComponent: PropTypes.func.isRequired,
  userChangesPresent: PropTypes.bool.isRequired,
  setUserChangesPresent: PropTypes.func.isRequired,
  runSave: PropTypes.bool.isRequired,
  setRunSave: PropTypes.func.isRequired,
  customDropdownSources: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)),
  customOnChange: PropTypes.func,
  saveDialogPromiseCallback: PropTypes.func,
  scrollToRow: PropTypes.number,
  setScrollToRow: PropTypes.func,
  afterSaveGoTo: PropTypes.string,
  customFilter: PropTypes.bool,
  transformationParams: PropTypes.objectOf(PropTypes.any),
  setTransformationParams: PropTypes.func,
};
Grid.defaultProps = {
  gridLayout: null,
  customDropdownSources: {},
  customOnChange: () => {},
  saveDialogPromiseCallback: (resolve) => resolve(null),
  scrollToRow: undefined,
  setScrollToRow: () => {},
  afterSaveGoTo: 'table',
  customFilter: false,
  transformationParams: {},
  setTransformationParams: () => {},
};
