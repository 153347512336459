{
  "general": {
    "findoutmore": "Find out more",
    "getdemo": "Live demo",
    "startDemo": "Try a demo",
    "search": "Search",
    "noResultsFound": "No results found",
    "freetrial": "Start free trial",
    "howdowedoit": "How do we do it?",
    "language": "Language",
    "english": "English",
    "german": "German",
    "country": "Country",
    "germany": "Germany",
    "send": "Send",
    "ok": "OK",
    "error": "Error",
    "yes": "Yes",
    "no": "No",
    "success": "Success",
    "submit": "Submit",
    "continue": "Continue",
    "cancel": "Cancel",
    "next": "Next",
    "back": "Back",
    "subscribe": {
      "header": "Subscribe to our newsletter",
      "body": "The latest updates, articles, and resources, sent to your inbox every 2 months.",
      "emailprompt": "Enter your email",
      "button": "Subscribe"
    },
    "step": "Step",
    "stepOf": "of",
    "findOutMore": "Find out more",
    "synchronising": "Synchronising"
  },
  "login": {
    "invalidUsernameOrPassword": "Invalid username or password",
    "userNotConfirmed": "User has not been confirmed. Please contact support at support@monestry.de",
    "passwordResetRequired": "Your password must be reset, please do so using the 'Forgot your password?' link below.",
    "incorrectConfirmationCode": "The confirmation code you entered is incorrect. Please try again.",
    "unknownUser": "We could not find a user who would have the email address given to us by the external identity provider you selected.",
    "signInIntoYourAccount": "Sign in to your account",
    "signInWith": "Sign in with",
    "enterConfirmationCode": "Enter authorisation code from your app",
    "enterConfirmationCodeEmail": {
      "tip": "We have sent you an email with confirmation code. Please enter it here.",
      "label": "Enter confirmation code from email",
      "error": "Code must be 6 digits long"
    },
    "orContinueWith": "or continue with",
    "emailOrUsername": "Email or username",
    "password": "Password",
    "rememberMe": "Remember me",
    "forgotPassword": "Forgot your password?",
    "signIn": "Sign in",
    "fieldRequired": "This field is required.",
    "passwordRecovery": "Recover your password",
    "unableToResetPassword": "We were not able to reset your password. Please try again later.",
    "enterNewPassword": "Enter a new password",
    "tooManyRequests": "To protect your data we had to block password changes for this account for 15 minutes. We detected too many password change attempts."
  },
  "menu": {
    "features": "Features",
    "anonymous": "Anonymous use",
    "data": "Data sources",
    "blog": "Blog",
    "meet": "Meet Monestry",
    "pricing": "Pricing",
    "demo": "Live demo",
    "dashboard": "Dashboard",
    "login": "Log in",
    "logout": "Log out",
    "register": "Sign on",
    "product": "Product",
    "support": "Support",
    "guide": "User Guide",
    "howto": "How-To Guides",
    "contact": "Contact",
    "company": "Company",
    "about": "About",
    "jobs": "Jobs",
    "press": "Press",
    "partners": "Partners",
    "legal": "Legal",
    "impressum": "Impressum",
    "privacy": "Privacy",
    "agb": "Terms"
  },
  "main": {
    "cta": {
      "line-1": "Make your money",
      "line-2": "work as hard as you",
      "body-1": "Take the right decisions with Monestry, your trusted, independent financial planning app.",
      "body-2": "A truly complete picture of your wealth: past, present and future. Automatic updates from over 6,000 institutions worldwide."
    },
    "partners": "Our trusted partners",
    "convenience": {
      "header": "Convenience",
      "title": "We are here to make your life easier",
      "subtitle": "Monestry was created to remove effort and complexity from managing your personal finances. Let us put you in charge of your financial future."
    },
    "complete": {
      "title": "A truly complete picture",
      "body": "We know that your wealth is more than bank accounts and stocks. That is why make it possible to track a variety of many other asset categories: from real estate through cryptocurrencies and bars of precious metals to objects of value and shares of unlisted companies.",
      "sub1": {
        "title": "Real estate",
        "body": "Apartments and houses, self-used or rented out, can be a very significant part of your assets. We make it possible to track the value of that investment in an easy way."
      },
      "sub2": {
        "title": "Pension insurance",
        "body": "A complete view of of your finances has to include pension insurances. They can play a significant part in how you handle your retirement finances."
      }
    },
    "current": {
      "title": "Current value at a click",
      "body": "Making a list of all your assets and their value today is only part of task. Tomorrow many of those assets will have a different value. And the day after tomorrow - different again. Monestry makes sure that all your asset classes always remain up to date.",
      "sub1": {
        "title": "Stocks, currencies and precious metals",
        "body": "We take care of automatic updates to all the stocks, currencies, cryptocurrencies and precious metals in your accounts."
      },
      "sub2": {
        "title": "Market value of your real estate",
        "body": "You will receive a free current market value update every 6 months for every real estate object in your portfolio. We use the same providers that the banks use to assess the value of your property.",
        "link": "How do we do it?"
      }
    },
    "security": {
      "header": "State of the art",
      "title": "Latest technology keeps your data safe",
      "body": "We use state-of-the-art encryption and security technology for your data and all our systems. You can stay anonymous, and your account information is never stored together with your financial data."
    },
    "simple": {
      "title": "See the future",
      "body": "How would you like to know that the future holds? We cannot tell you that for certain, but we can help. Use our simulations and projects to see how your wealth could look like in 10, 20 or 40 years.",
      "link": "See how it works",
      "quote": "\"It's incredibly reassuring to see how my investments could grow over time. This tool has genuinely helped me feel more confident about my long-term financial strategy.\"",
      "name": "Anna - Frankfurt am Main, Germany"
    },
    "simpleOLD": {
      "title": "Simplicity first",
      "body": "Modern life and modern work can be very complex. That is why making Monestry as simple to use as possible is our first priority. We weed out irrelevant aspects of wealth management and provide predefined German financial product templates.",
      "link": "Featured products",
      "quote": "Cras velit quis eros eget rhoncus lacus ultrices sed diam. Sit orci risus aenean curabitur donec aliquet. Mi venenatis in euismod ut.",
      "name": "Jane - Frankfurt am Main, Germany"
    },
    "saving": {
      "title": "Saving you time",
      "body": "There are better things to do than sitting in front of a spreadsheet and copy-pasting columns of numbers - even if the numbers are your constantly growing assets. Let us take care of importing and structuring all the data, so that you can focus on what is relevant - taking the right decisions about your future.",
      "link": "Find out more",
      "quote": "\"I don't waste time on spreadsheets anymore. They handle all the data stuff, so I can just make good choices for my future. It's a lot easier and saves me heaps of time.\"",
      "name": "Peter - Bad Homburg, Germany"
    }
  },
  "feedback": {
    "title": "Your feedback",
    "problem": "Problem",
    "idea": "Idea",
    "question": "Question",
    "header": "What would you like to tell us?",
    "email": "Send me an email once the issue is tackled.",
    "completed": {
      "success": "Thank you for contacting us. If you ever need to reach out to us about this again, please use the following reference:",
      "failure": "We are sorry: sending feedback was not possible at this time. Our support team has been notified. Please try again later."
    },
    "beingUploaded": "Your feedback is being uploaded. Please wait.",
    "uploadAttachment": "Upload an attachment",
    "uploadSizeLimit": "Maximum size: 5MB"
  },
  "dashboard": {
    "accounts_header": "Your Accounts"
  },
  "page-not-found": {
    "header": "We are sorry",
    "title": "This page does not exist",
    "body": "Please use the menu links to visit one of the other sections.",
    "link": "Go back",
    "homePage": "Home page"
  },
  "blog": {
    "title": "Aus unserem Blog",
    "subtitle": "Stöbere durch die Artikel und finde Tipps, wie man Monestry besser nutzen kann, unsere selbstbewussten Beiträge zu diversen finanziellen Themen sowie Ankündigungen neuer Produktmerkmale.",
    "filter-header": "Filter by category:",
    "categories": {
      "blog": {
        "filter-title": "All",
        "title": "From the blog",
        "subtitle": "Browse through the articles and find tips on getting more out of Monestry, our opinionated articles on several financial topics and new feature announcements."
      },
      "guide": {
        "title": "Product guide",
        "subtitle": "These articles describe all the product features in detail and provide tips on how to use them."
      },
      "new-features": {
        "title": "Product announcements",
        "subtitle": "At Monestry we continuously develop our product to make managing your finances easier. Here you will find new feature announcements."
      },
      "point-of-view": {
        "title": "Point of view",
        "subtitle": "We have strong opinions on how personal finances should be managed. Here you will find our thoughts on several financial topics."
      },
      "market-overview": {
        "title": "Market overview",
        "subtitle": "We keep our finger on the pulse of the financial app market. Here you will find our thoughts on the latest developments."
      }
    }
  },
  "article": {
    "return-prev-page": "Back to previous page",
    "written-by": "Written by",
    "published": "Published",
    "updated": "updated",
    "related-articles": "Related articles",
    "fixed-articles-header": "Simplify your finances with Monestry",
    "features-title": "Features: what can we do for you?",
    "demo-title": "Live demo: try it out - no registration!"
  },
  "features": {
    "section1": {
      "header": "Your finances up to date",
      "header-accent": "automatically",
      "body": "Monestry makes it simple to manage your personal finances by giving you a complete picture of all your assets and their changing value.",
      "one-glance": {
        "title": "See your wealth at one glance",
        "body": "Dashboard is the heart of our App. This is where you see what is the current state of your finances and how are individual assets performing. Its intuitive design guides you to all other functions of the App.",
        "quote": "\"The dashboard really simplifies things for me. It gives a clear picture of my finances and, most importantly, how are all my assets doing, all in one place.\"",
        "person": "Lukas - Frankfurt am Main, Germany"
      },
      "all-classes": {
        "header": "Manage all asset classes",
        "body": "Realize the full extent of your wealth and start considering them in your plans. You may be surprised how big is the difference when you have a whole picture.",
        "bank": {
          "header": "Bank assets",
          "body": "The classic. Keep track of your cash savings across as many banks as you like."
        },
        "stocks": {
          "header": "Securities",
          "body": "From your broker or from your employer, you can track all kinds of securities and options with Monestry."
        },
        "real-estate": {
          "header": "Real estate",
          "body": "Keep track of what your real estate is worth, and if you rent it out - find out what your real returns are."
        },
        "pension": {
          "header": "Pension",
          "body": "Monitor performance of your pension investments: state pension and captial insurances, Riester, Rürup and bAV."
        },
        "unlisted": {
          "header": "Unlisted companies",
          "body": "Do you know what is your business worth and what is the current return on investment? We can help with professional estimates."
        },
        "crypto": {
          "header": "Cryptocurrency",
          "body": "A new class of assets which can become very valuable in a short period of time."
        },
        "objects": {
          "header": "Objects of value",
          "body": "Bars of precious metals, oldtimer cars, watches, coins and jewellery can be a significant part of one's wealth"
        },
        "loans": {
          "header": "Loans",
          "body": "Owed or taken out, loans can be substantial components of your financial situation."
        }
      }
    },
    "section2": {
      "lead": "Automatic updates",
      "header": "Know what your assets are worth",
      "body": "Do you know how much your net worth is right now? Monestry helps you to stay on top of that.",
      "valuation": {
        "stocks": {
          "header": "Stock quotes",
          "body": "All securities across all your accounts are updated daily with their current price, so that you know how the current market trends influence your net worth."
        },
        "unlisted": {
          "header": "Shares of unlisted companies",
          "body": "We give you the tools to estimate what a share in an unlisted company may be worth to help you deal with partners, investors and banks."
        },
        "metals": {
          "header": "Precious metals",
          "body": "Monestry gives you an estimate of current market value and spread for the precious metals that you may own."
        },
        "fx": {
          "header": "Foreign exchange rates",
          "body": "Do you remember 1€ for $0.85 in 2000? And 1€ for $1.46 just eight years later? Foreign currency fluctuations can significantly change your assets\\' value. Monestry keeps it up to date."
        },
        "real-estate": {
          "header": "Real estate",
          "body": "Every 6 months Monestry will provide you with the current market value of your properties, stemming from an analysis of more than 200,000 current sale transactions in Germany."
        }
      }
    },
    "section3": {
      "goals": {
        "body": "Do you know when will you be able to retire? Can you finance your dream vacation house from your stock portfolio gains or will you need a loan? Answer these and other questions by using Monestry's goals and simulations feature.",
        "header": "Create goals and simulations",
        "set": {
          "header": "Set financial goals",
          "body": "Determine how much money will you need to finance future projects and plan what accounts will that money come from."
        },
        "simulate": {
          "header": "Simulate future growth",
          "body": "Check your net worth at different moments in the future to see how much your different assets are worth then."
        }
      },
      "reports": {
        "body": "Create your own analyses and visualisations to have more insights into your finances.",
        "header": "Use customisable reports",
        "pivot": {
          "header": "Pivot tables",
          "body": "If the pre-set reports are not enough, use a pivot table format to prepare exactly the data that you want to see."
        },
        "charts": {
          "header": "Design charts",
          "body": "Create beautiful charts using Monestry's flexible charting engine."
        },
        "keep-eye": {
          "header": "Monitor the situation easily",
          "body": "Embed your own charts in your Dashboard to see the most important information every time you visit."
        }
      }
    },
    "section4": {
      "sync": {
        "header": "Keep data in sync",
        "auto": {
          "header": "Automatic synchronisation",
          "body": "Get your data automatically from more than 60,000 financial institutions in Germany and abroad. We partner with a leading financial services data provider, active in the field for more than 10 years. Find out more."
        },
        "edit": {
          "header": "Edit data spreasheet-style",
          "body": "Use a familiar spreadsheet grid to enter and edit all data which need to be updated manually."
        },
        "uploads": {
          "header": "Intelligent uploads",
          "body": "For manual uploads, our simple and intuitive file upload analyses the data and suggests the correct mapping logic, easily adjustable per mouse click."
        },
        "decide": {
          "header": "You decide",
          "body": "You decide which accounts to update automatically and which will be updated with files. You can also change your settings at any time."
        }
      },
      "foundations": {
        "header": "Solid foundations",
        "body": "No experiments. Monestry is built with proven, state-of-the-art technologies, with strong focus on data privacy, security and sustainability.",
        "sustain": {
          "header": "Sustainable IT",
          "body": "No idling servers - Monestry IT resources are \"on\" only when they are being used."
        },
        "anonymous": {
          "header": "Anonymous mode",
          "body": "Use Monestry without disclosing your name. You can count on our help protecting you and your data."
        },
        "control": {
          "header": "Full control over your data",
          "body": "We pledge to never sell your data to any external company - check our T&C's. This isn't how we earn money."
        },
        "neutral": {
          "header": "Neutral",
          "body": "We do not try to sell you financial products and benefit from the commissions paid for vendors."
        },
        "security": {
          "header": "Security",
          "body": "'All data in storage and in transit are encrypted with private keys and not visible outside of Monestry"
        },
        "impartial": {
          "header": "Impartial",
          "body": "Monestry is independently owned and not influenced by any financial services provider."
        },
        "audit": {
          "header": "Regular audits",
          "body": "We schedule regular security audits by external consultants to keep our systems safe."
        },
        "german": {
          "header": "Made in Germany",
          "body": "Monestry was founded in Frankfurt and this is also where all its own IT systems are located."
        }
      },
      "cta": {
        "header": "Make your money work.",
        "header2": "Start using Monestry today.",
        "subheader": "One month for free. No credit card required. Cancel any time."
      }
    }
  },
  "pricing": {
    "header": "Choose the package that suits you best",
    "subheader": "We know that one size does not fit all. That's why we created four affordable packages for you to choose from.",
    "most-popular": "Most popular",
    "annually": "Annual billing",
    "monthly": "Monthly billing",
    "automaticUpdatesDisclaimer1": "* Some of your financial institutions may not support automatic updates. Please check the ",
    "automaticUpdatesDisclaimer2": "list of supported institutions.",
    "linkToAutoUpdates": "/en/blog/guide/how-do-we-keep-your-financials-current",
    "products": {
      "frequency": "/month",
      "s": {
        "title": "Monestry S",
        "description": "Automated updates of your assets for an easy start.",
        "features": [
          "Up to 5 accounts",
          "Automatic updates for German accounts*",
          "Automatic update for 1 foreign account*"
        ],
        "cta": "Get package",
        "mostPopular": false
      },
      "m": {
        "title": "Monestry M",
        "description": "Take control of your financial future.",
        "features": [
          "Up to 10 accounts",
          "Automatic updates for German accounts*",
          "Automatic updates for 2 foreign accounts*",
          "Automatic valuations for 1 real estate property"
        ],
        "cta": "Get package",
        "mostPopular": false
      },
      "l": {
        "title": "Monestry L",
        "description": "Finally a good overview of all your assets.",
        "features": [
          "Up to 15 accounts",
          "Automatic updates for German accounts*",
          "Automatic updates for 4 foreign accounts*",
          "Automatic valuations for 2 real estate properties"
        ],
        "cta": "Get package",
        "mostPopular": true
      },
      "xl": {
        "title": "Monestry XL",
        "description": "The complete picture of your wealth.",
        "features": [
          "Up to 30 accounts",
          "Automatic updates for German accounts*",
          "Automatic updates for 8 foreign accounts*",
          "Automatic valuations for 3 real estate properties"
        ],
        "cta": "Get package",
        "mostPopular": false
      }
    },
    "faq": {
      "header": "About our packages",
      "questions": [
        {
          "question": "What is an account?",
          "answer": "In Monestry each asset has its own account, e.g. a bank account, a brokerage account, a real estate property, a pension insurance, a loan etc. The number of accounts is limited by the package you choose. You can always upgrade to a larger package."
        },
        {
          "question": "What does >up to 5 / 10 / ... accounts< mean exactly?",
          "answer": "The account limit is the maximum number of accounts that you can have in Monestry. If you reach the limit, you will not be able to add new accounts until you upgrade to a larger package."
        },
        {
          "question": "Can I change my package after I purchase?",
          "answer": "Yes, you can change your package at any time, but only to a larger package. The change will come into effect immediatelly. You will be charged the difference between the two packages pro rata with your next payment."
        },
        {
          "question": "How does annual billing work?",
          "answer": "This is the more cost-effective approach to payment. The amount indicated above is multiplied times 12 (for the number of months in a year) and charged through your preferred means of payment once per year. The subscription is valid for 365/366 days and can commence at any day of the year. The subscription will be prolonged automatically. It can be cancelled by the end of the current subscription period - that means the subscription remains active until the end of year."
        },
        {
          "question": "How does monthly billing work?",
          "answer": "This option is good for trying out Monestry. The amount indicated above will be charged once per month. The subscription will be prolonged automatically. It can be cancelled at any time by the end of the current subscription period - the subscription remains active until the end of the monthly period. Switch to annual payment is possible and comes in effect as soon as the current monthly period finishes."
        }
      ]
    }
  },
  "legal": {
    "toc": "On this page",
    "read-full-doc": "Read entire document",
    "close": "Close preview",
    "dataPrivacyContact": "Please email us at support@monestry.de for all data privacy related inquiries, including listing and deleting user data.",
    "documents": [
      {
        "header": "Terms & Conditions",
        "subtitle": "This document describes the terms of use of our services as well as everything concering your data and what happens to it.",
        "body": "### Iam adimunt fertur \n\n Lorem markdownum metus etiam oppositas Antigonen enim Bacchei ungues et ultro Helenum artus, et me laudis fixa, et. Pariter [huius](http://www.sive.com/) precantia admonuisse quove. Nemus sed inpia templis [capere](http://gravis.com/in), mihi colit ponit: illo proles thymi pactus datis iam ranas victus. \n\n Ibi usque vi Tantalis sonarent carpunt et. Neque manes: mensuraque, gratissime materia praebet nubibus quos: perque. Ante regnat feram? Ulciscere ecquem triplex. Nullam Magnetas puerile licet; Iovis priores quoque invenies laudat spectat pectine. \n\n ### Sexque et naribus motis tauri toto est. \n\n Subire ignarus mulcet argento penetrabile curvo hic Cinyran sinus **concipit**. Generosaque quae non spumantia ossibus tulit demens igne suam, Quirini illis retorserunt parte inhibente. Orbem passa est finite pars *spoliata Melantho* foedat. Quod neque tumentem dum. \n\n ### Mihi arbore possemque \n\n Quas habet, maledicere in est genitor capitis licet; bis terra est nomine, verso. Tale ille plausu huius, thalamis Hippomenen necem vocali si. Herbas obsuntque formae ventis, iuvenes maris me aliena nec mollia cremabis tempora velut. Formae sine parsque \\[Caeneus\\](http://horrifer-an.com/fumida.aspx) quoque exercere longa profani cessantem esse. \n\n ### Crudelia et lacu amplectens nomina misisset \n\n Et iactis refundit mecum. Pennatis sic, **in ulla** Ithaco: licebit quattuor non Ceres fraxinus praesignis colore commentaque."
      },
      {
        "header": "Impressum",
        "subtitle": "This is a legally mandated document outlining the specifics of this website.",
        "body": "### Iam adimunt fertur \n\n Lorem markdownum metus etiam oppositas Antigonen enim Bacchei ungues et ultro Helenum artus, et me laudis fixa, et. Pariter [huius](http://www.sive.com/) precantia admonuisse quove. Nemus sed inpia templis [capere](http://gravis.com/in), mihi colit ponit: illo proles thymi pactus datis iam ranas victus. \n\n Ibi usque vi Tantalis sonarent carpunt et. Neque manes: mensuraque, gratissime materia praebet nubibus quos: perque. Ante regnat feram? Ulciscere ecquem triplex. Nullam Magnetas puerile licet; Iovis priores quoque invenies laudat spectat pectine. \n\n ### Sexque et naribus motis tauri toto est. \n\n Subire ignarus mulcet argento penetrabile curvo hic Cinyran sinus **concipit**. Generosaque quae non spumantia ossibus tulit demens igne suam, Quirini illis retorserunt parte inhibente. Orbem passa est finite pars *spoliata Melantho* foedat. Quod neque tumentem dum. \n\n ### Mihi arbore possemque \n\n Quas habet, maledicere in est genitor capitis licet; bis terra est nomine, verso. Tale ille plausu huius, thalamis Hippomenen necem vocali si. Herbas obsuntque formae ventis, iuvenes maris me aliena nec mollia cremabis tempora velut. Formae sine parsque \\[Caeneus\\](http://horrifer-an.com/fumida.aspx) quoque exercere longa profani cessantem esse. \n\n ### Crudelia et lacu amplectens nomina misisset \n\n Et iactis refundit mecum. Pennatis sic, **in ulla** Ithaco: licebit quattuor non Ceres fraxinus praesignis colore commentaque."
      }
    ]
  },
  "about-us": {
    "need-for-monestry": {
      "header": "Why does the world need Monestry?*",
      "subheader": "Monestry arose from the desire for having a simple, automated app which provides a very clear picture of your finances' past, present and future, and the realisation that the tools available on the market fell short of this expectation.",
      "paragraphs": [
        "Monestry is intended for people who want to take complete charge of their own financial fate and who do not want to be distracted by apps to sell them financial products.",
        "We believe that free finance management apps do not have the customers' best interest at heart. The price paid for engaging in such model is often loss of control of your most sensitive data or being led into making wrong financial choices.",
        "Our deal is simple: we help you keep the financial information current and provide automated tools to make good financial decisions.",
        "We don't want your personal data and will never sell the data you decided to share. All that kept as simple as humanly possible, as the world is already complex enough."
      ],
      "footnote": "* Or, in the words of some of our friends: \"Why create yet another financial app?\""
    },
    "pillars": [
      {
        "key": "simplicity",
        "header": "Simplicity",
        "description": "The world is complex enough on its own, so we make keeping things simple our top priority. Even though some financial products are complicated, we want to let you focus on their most important parameters. \n\n We want to keep the surface layer of our product as simple as we can. Once you are comfortable enough to deal with finer details, you can adjust most the settings in an easy way.",
        "cta": "See how it looks"
      },
      {
        "key": "privacy",
        "header": "Privacy and Trust",
        "description": "Data privacy has been one of our primary concerns since way before we even wrote a single line of code. \n- we created the “Anonymous Mode”, where you can use the product without disclosing your name or address to us \n- your account data are physically separated from your financial data to double the layer of protection \n- there are automatic mechanisms in place to report on and delete all user data on request.",
        "cta": "See how we apply it in practice"
      },
      {
        "key": "convenience",
        "header": "Convenience",
        "description": "It is possible to keep track of all your bank and brokerage accounts with a spreadsheet, but we believe that you would prefer to spend your free time differently. \n\n This is where we step in. Everything that can be updated automatically, will be updated automatically. If automation is not an option, we provide several simple ways to help you update the data yourself. \n\n We want you to have the most current information in your browser or on your phone. No more cumbersome manual updating of spreadsheet columns!",
        "cta": "See how can we save you time"
      }
    ],
    "pledges": {
      "title": "Our values",
      "header": "The three promises of Monestry",
      "subheader": "Here is our promise to you.",
      "items": [
        {
          "key": "one",
          "header": "We will never pressure you to buy financial products from which we earn commission.",
          "subheader": "We believe you are smart enough to be able to choose the financial products that are right for you on your own. We want to be neutral and unbiased towards any and all the products providers."
        },
        {
          "key": "two",
          "header": "Users have full control of their data. We will never sell your data to anyone.",
          "subheader": "It says so in our T&Cs, so you may rest assured that any data you entrust to us, remains under your full control."
        },
        {
          "key": "three",
          "header": "We do not want to know who the users are and we provide a way for users to stay anonymous.",
          "subheader": "We do not trade our users' data, so we have no need to know their identities. Increase your data privacy by remaining anonymous - increase your comfort level by trusting us with more data."
        }
      ]
    },
    "about": {
      "header": "About Monestry",
      "body": "Monestry is a start-up company founded in 2021 in Frankfurt am Main in Germany by Piotr Loboda, a German with Polish roots. For over 20 years Piotr has been working as a professional IT architect and IT director in different companies in the financial services industry. You can find out more about Piotr here. \n\n Monestry is 100% funded by private German investors.\n\n Since its inception, Monestry GmbH is a member of the Frankfurt am Main Industry Chamber and is registered in the Frankfurt am Main Trade Register under the registry number HRB 123844. \n\n Monestry has been recognised and backed by grants from different public and private organisations, such as Amazon Web Services, Stripe and different SMU support funds of the European Union. \n\n We partnered with different organisations and companies to provide the best kind of technology solutions to our clients. Among those: GoCardless, FinAPI, Faireal, Deutsche Bundesbank, European Central Bank etc.   \n\n For media inquires please email media@monestry.de. \n For commercial inquires please email partners@monestry.de."
    },
    "why-monestry": {
      "header": "Why \"Monestry\"?",
      "body1": "We have been looking for a name which is clearly linkable to money as such, easy to understand in different countries, not too long and easy to remember. We have combed through scores and scores of names (most of them already taken) some of them internally brainstormed and some of them created with the help of artificial intelligence and finally settled on “Monestry”.",
      "body2": "“Monestry” combines the affluence, stability and discretion of a cistercian monastry in a luscious French forrest with much more secular money which we all need to fulfil our earthly dreams. We pronounce it",
      "body3": "MOH-neh-stree."
    }
  },
  "register": {
    "select": "Select",
    "username": {
      "header": "Sign up - it's easy!",
      "subheader": "Step 1 of 3. Create a Monestry account or link an existing one."
    },
    "package": {
      "header": "Almost there",
      "subheader": "Step 2 of 3. Which tier would you like to start with?"
    },
    "payment": {
      "header": "Payment details",
      "subheader": "Save payment details now or at the end of the free trial. No charges today!"
    },
    "processing": {
      "header": "Welcome to Monestry!",
      "subheader": "Please wait while we are settings things up for you."
    },
    "paymentStillProcessed": "Your payment is still being processed.",
    "pleaseWaitWhileWeAreSettingThingsUp": "Please wait while we are setting things up for you.",
    "goBack": "Go back",
    "signUp1": {
      "createNewAccount": "Create a new account",
      "email": {
        "label": "Your email address",
        "errorEmpty": "Please specify an email address.",
        "errorInvalid": "Your email address does not seem valid.",
        "errorLength": "Your email cannot be longer than 200 characters",
        "errorTaken": "This email is unfortunately not available.",
        "tooltip": "We would like to contact you if you need to reset your password. You can use your email to login as well."
      },
      "password": {
        "label": "Pick a password",
        "hint": "Your password must be at least 8 characters long and must contain at least one of the following: capital letter, small letter, number and a punctuation character.",
        "errorEmpty": "Please provide a password.",
        "errorLength": "Your password cannot be longer than 99 characters",
        "errorPattern": "Your password does not meet the requirements."
      },
      "repeatPassword": {
        "label": "Repeat password",
        "errorMatch": "The repeated password is not the same as the new password."
      },
      "username": {
        "label": "Username",
        "tooltip": "Please choose a username so that we know how to address you. Later, you can use your EMAIL or your USERNAME to log in.",
        "errorRequired": "Please specify a username. We just need to how to call you.",
        "errorLength": "Your username cannot be longer than 40 characters",
        "errorMinLength": "Your username cannot be shorter than 5 characters",
        "errorPattern": "You may only use letters, numbers and . - _ in your username.",
        "errorTaken": "This username is unfortunately not available."
      },
      "country": {
        "label": "Country of residence",
        "tooltip": "Why do we need to know? For tax reasons: for now we can only bill customers who tell us they reside in Germany.",
        "Germany": "Germany"
      },
      "baseCurrency": {
        "label": "Base currency",
        "tooltip": "Which currency would you like to serve as your base currency? Typically this is the currency you earn in."
      },
      "or": "or",
      "useExistingAccount": "... or use an existing account.",
      "useExistingAccountDescription": "You can use an existing account to register with Monestry instead of creating an account here. You will then be able to use that account every time you log in to Monestry.",
      "signUpWith": "Sign up with"
    },
    "signUp3": {
      "yourOrder": "Your order",
      "creditCard": "Credit card",
      "crypto": "Cryptocurrencies",
      "payNow": "Save now",
      "payLater": "Add later",
      "freeUntil": "Free until",
      "afterwards": "afterwards",
      "perMonth": " /month",
      "day": "day",
      "dueToday": "Billed today",
      "dueIn30Days": "Billed on",
      "yearBilling": "billed once a year.",
      "monthBilling": "billed every month.",
      "subscriptionRenewsOn": "First month is free. Your subscription will renew and bill you automatically",
      "everyyear": "every year on the",
      "everymonth": "every month on the",
      "reminderEmail": "You will receive an email reminder before the free period ends.",
      "cancelAnyTime": "Cancel any time. After cancellation your subscription will still be valid until the end of trial or the paid for period.",
      "upgradeAnyTime": "Upgrades to a higher product tier are possible at any time.",
      "upgradeSwitchToAnnual": "Upgrades to a higher product tier and switching to discounted annual payment are possible at any time.",
      "externalInfo": "For your security, logging in with {{provider}} might prompt you to confirm your access more than one time."
    },
    "paymentSuccessful": {
      "stillProcessed": "Your payment is still being processed.",
      "registrationSuccessful": "Registration successful",
      "welcome": "Welcome!",
      "thanksForOpening": "Thank you for opening an account with Monestry.",
      "weSentYouAnEmail": "We have sent you an email to confirm your address. While you wait, why don't you check out short introductory video to the right?",
      "enterCode": "Once you are ready please enter the 6-digit code from that email into the box and press the button below.",
      "verificationCode": "Verification code",
      "confirmAccount": "Confirm account",
      "codeError": "This is not the correct code",
      "genericError": "We are sorry, an unhandled error has occurred. Please email us at support@monestry.de with the email address you just used and this error message.",
      "federated": {
        "accountReady": "Your account is ready. You will be able to log on with {{provider}} as requested.",
        "addPasswordLater": "If at any time you would like to add Monestry password to your account, you can do so by resetting your password from the Login screen.",
        "proceedToDashboard": "Proceed to dashboard"
      },
      "errorMessage": {
        "registrationFailed": "Registration failed",
        "error": "Error",
        "thankYouForOpening": "Thank you for opening an account with Monestry.",
        "problemWithPayment": "Unfortunately there seems to be a problem with your payment. Please reach out to our support team at support@monestry.de ."
      }
    },
    "fallback": {
      "header": "Email not available",
      "body1": "Some providers (e.g. Facebook) do not share your verified email address under some specific circumstances. We cannot create an account for you without an email address.",
      "body2": "Please enter an email address and a password to create an account with us.",
      "submit": "Submit",
      "unhandledError": {
        "header": "We are sorry, but something went wrong",
        "body1": "Your payment was most likely successful, but we have not been able to create your account.",
        "body2": "Please go to our homepage and try to log in with the credentials you have just used to register. If you are not able to log in, please reach out to our support team at support@monestry.de",
        "message": "Please send us the following error message:"
      }
    },
    "prep": {
      "header": "Creating sample data",
      "body": "We are preparing some sample data to get you started. This should only take a few seconds.",
      "account0": "[DEMO] My bank account",
      "account1": "[DEMO] Brokerage cash account",
      "account2": "[DEMO] Savings account",
      "account3": "[DEMO] My stock broker",
      "account4": "[DEMO] My real estate",
      "project0": "[DEMO] Living",
      "initialSaldo": "Initial saldo",
      "purchaseAppleStock": "Purchase Apple stock",
      "transactionFees": "Transaction fees",
      "purchaseTeslaStock": "Purchase Tesla stock",
      "accountRunningCost": "Account running cost",
      "dividendAppleStock": "Dividend Apple stock",
      "salary": "Salary",
      "rentPayment": "Rent payment",
      "creditCardBill": "Credit card bill",
      "carRental": "Car rental",
      "hotelBill": "Hotel bill",
      "interestPayment": "Interest payment",
      "otherParty": "Other party"
    },
    "code": {
      "label": "Registration code",
      "link": "Have a registration code?",
      "errorInvalid": "This code is not valid."
    }
  },
  "requestDemo": {
    "topheader": "Before you start",
    "Ready to set up your demo": "Ready to start your demo?",
    "header1": "We would like to get to know our potential users better.",
    "header2": "Would you mind answering four simple questions?",
    "numberOfAccounts": {
      "label": "How many different financial products do you have worldwide?",
      "sublabel": "Including bank accounts, brokerage accounts, pension accounts, etc.",
      "options": [
        "0 - 5",
        "6 - 10",
        "11 - 20",
        "20+"
      ]
    },
    "incomeSaved": {
      "label": "What percentage of your income is your household able to save or invest?",
      "options": [
        "<10%",
        "10% - 20%",
        "20% - 30%",
        "30% - 40%",
        "40% - 50%",
        ">50%"
      ]
    },
    "age": {
      "label": "How old are you?",
      "options": [
        "<25",
        "26-35",
        "36-45",
        "46-55",
        "56-65",
        "65+"
      ]
    },
    "workplace": {
      "label": "Where do you work?",
      "options": [
        "Large company (more than 10k employees)",
        "Small/medium company (up to 10k employees)",
        "I own a company",
        "I am self-employed"
      ]
    },
    "howCanWeHelp": {
      "label": "How do you think Monestry can help you? (optional)"
    },
    "Cancel": "Cancel",
    "Start demo": "Start demo",
    "weAreSettingThingsUp": "Please wait while we are preparing your demo account."
  },
  "cookies": {
    "title": "Cookies",
    "description": "We use both necessary and optional cookies on our website to operate and continuously improve our app. Your consent includes all pre-selected/selected cookies as well as the associated storage and loading of information on your device and subsequent loading and processing of personal data.",
    "noTracking": "We do not serve ads nor use any cookies for tracking purposes (these are those which link data from this app to 3rd party data about the user).",
    "legalBasis": "The legal basis for consent regarding the storage and loading of information is § 25 para. 1 TTDSG, and regarding the processing of personal data, Art. 6 para. 1 lit. a GDPR.",
    "settingsInfo": "You can accept non-essential cookies ('Accept all') or reject them ('Only essential'). Your settings can be modified at any time via a link in the footer of our website.",
    "privacyPolicy": "Further information on the procedures used and your rights can be found in our privacy policy.",
    "onlyEssential": "Only essential",
    "customise": "Customise",
    "acceptAll": "Accept all",
    "customiseTitle": "Customise cookies",
    "customiseSubtitle": "You can customise the cookies you want to allow on this website.",
    "essential": {
      "label": "Essential",
      "description": "These cookies are necessary for the website to function properly."
    },
    "marketing": {
      "label": "Marketing",
      "description": "These cookies are used to provide you with personalised offers and content."
    },
    "analytics": {
      "label": "Analytics",
      "description": "These cookies help us understand how the users use our App and make the user experience better."
    },
    "externalMedia": {
      "label": "External media",
      "description": "These cookies allow us to show you external media such as YouTube videos into our App."
    },
    "save": "Save",
    "goBack": "Go back"
  },
  "contact": {
    "header": "Contact us",
    "subheader": "How may we help you?",
    "goToLogin": "Log in",
    "goToDashboard": "Go to dashboard",
    "emailUs": "Email us",
    "prospects": {
      "header": "Product questions",
      "body": "If you have any questions about our product, please email us at support@monestry.de"
    },
    "users": {
      "header": "Feedback | bugs | ideas",
      "body": "If you have any feedback, ideas or you found a bug, please use the Feedback button which shows up in the bottom-right corner of the screen once you have logged in."
    },
    "dataPrivacy": {
      "header": "Delete account | data privacy requests",
      "body": "You can cancel your account in the app, but you can also email us at support@monestry.de . You can use the same email for any data-privacy-related requests."
    },
    "partners": {
      "header": "Partnerships",
      "body": "If you have a partnership idea and would like to discuss it with us, please reach out to us at partners@monestry.de"
    },
    "media": {
      "header": "Media inquiries",
      "body": "If you are a journalist and would like to write about us, please email us at media@monestry.de"
    }
  }
}