/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
import React, { useLayoutEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CheckCircleIcon, ChevronDownIcon, ChevronRightIcon, PlusIcon, TagIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid';
import TransactionContextMenu from './TransactionContextMenu';
import BlueTransactionsDialog from './BlueTransactionsDialog';

function showBlueTransactionsInDialog(transactionId, category) {
  // show dialog with passcode prompt, ask for passcode, dispatch the below in callback
  window.dispatchEvent(
    new CustomEvent('setDialog', {
      detail: {
        Component: BlueTransactionsDialog,
        props: { visible: true, transactionId, category },
      },
    }),
  );
}

/**
 * Only displays the label for whitelisted labels.
 *
 * @param {} param0
 * @returns
 */
function LabelButton({ transactionId, label }) {
  LabelButton.propTypes = {
    transactionId: PropTypes.string.isRequired,
    label: PropTypes.string,
  };
  LabelButton.defaultProps = {
    label: null,
  };

  const { t } = useTranslation();
  const approvedLabel = label?.includes('pension-') || label?.includes('investment-');
  const category = label?.includes('pension-') ? 'pension' : 'deposits';

  return (
    <button type="button" className="h-full text-gray-400 cursor-pointer" onClick={() => showBlueTransactionsInDialog(transactionId, category)}>
      {/* REGULAR VARIANT */}

      <div className="hidden sm:block">
        {approvedLabel && label ? (
          <span className="inline-flex items-center justify-center rounded-md bg-brandBlue-100 hover:bg-brandBlue-200 px-2.5 py-0.5 text-xs font-medium text-brandBlue-700">
            {t(`app:accountDetails.${category}.labels.${label}.short`)}
          </span>
        ) : (
          // eslint-disable-next-line max-len
          <span className="inline-flex items-center justify-center rounded-md border border-gray-100 bg-white hover:bg-gray-50 px-2.5 py-0.5 text-xs font-medium text-gray-300">
            {t('app:accountDetails.deposits.addTag')}
          </span>
        )}
        <span className="sr-only">{label}</span>
      </div>

      {/* XS- BREAKPOINT VARIANT */}

      <div className="sm:hidden">
        {approvedLabel && label ? (
          <span className="inline-flex items-center justify-center rounded-lg bg-brandBlue-100 hover:bg-brandBlue-200 border border-brandBlue-100 p-2 text-xs font-medium text-brandBlue-400">
            <TagIcon className="w-5 h-5" />
          </span>
        ) : (
          // eslint-disable-next-line max-len
          <span className="inline-flex items-center justify-center rounded-lg border border-gray-200 bg-white hover:bg-gray-50 p-2 text-xs font-medium text-gray-300">
            <PlusIcon className="w-5 h-5" />
          </span>
        )}
        <span className="sr-only">{label}</span>
      </div>
    </button>
  );
}

function showContextMenuInDialog(transactionId) {
  // show dialog with passcode prompt, ask for passcode, dispatch the below in callback
  window.dispatchEvent(
    new CustomEvent('setDialog', {
      detail: {
        Component: TransactionContextMenu,
        props: { visible: true, transactionId },
      },
    }),
  );
}

function EllipsisButton({ transactionId }) {
  return (
    <button type="button" className="h-full text-gray-400 cursor-pointer border border-gray-200 p-2 sm:p-0 rounded-md sm:border-0" onClick={() => showContextMenuInDialog(transactionId)}>
      <div>
        <EllipsisVerticalIcon className="h-5 w-5 sm:h-6 sm:w-6 text-gray-300 sm:text-gray-400 hover:text-gray-500" aria-hidden="true" />
        <span className="sr-only">Transaction context menu</span>
      </div>
    </button>
  );
}
EllipsisButton.propTypes = {
  transactionId: PropTypes.string.isRequired,
};

function ButtonToggleRowDetail({ value, openDetailRows, toggleDetailRow }) {
  // return a button with chevron icon that toggles the detail row
  return (
    <button type="button" id="toggle-detail-rows" className="hidden pl-1 xs:pl-0 xs:block xs:mr-2 h-full text-gray-400 cursor-pointer" onClick={() => toggleDetailRow(value)}>
      <div>
        {openDetailRows.includes(value) ? (
          <ChevronDownIcon className="h-5 w-5 sm:h-6 sm:w-6 text-gray-400 hover:text-gray-500" aria-hidden="true" />
        ) : (
          <ChevronRightIcon className="h-5 w-5 sm:h-6 sm:w-6 text-gray-400 hover:text-gray-500" aria-hidden="true" />
        )}
        <span className="sr-only">{openDetailRows.includes(value) ? 'Hide details' : 'Show details'}</span>
      </div>
    </button>
  );
}
ButtonToggleRowDetail.propTypes = {
  value: PropTypes.string.isRequired,
  openDetailRows: PropTypes.array.isRequired,
  toggleDetailRow: PropTypes.func.isRequired,
};

// helper function for formatting strings according to their type
function formatString(str, columnType, currency = null, secondaryCurrency = null) {
  if (columnType === 'currency' && !currency) throw new Error(`TableBody > formatString: Currency is required for currency formatting for arguments ${str} ${columnType}`);
  if (str === undefined || str === null) return '';

  switch (columnType) {
    case 'date':
      return new Date(Number(str)).toLocaleDateString('de', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        timeZone: 'UTC',
      });
    case 'currency0Decimals':
      return str.toLocaleString('de', { style: 'currency', currency: currency || 'EUR', maximumFractionDigits: 0 });
    case 'currency':
      return str.toLocaleString('de', { style: 'currency', currency: currency || 'EUR', maximumFractionDigits: 2 });
    case 'fxCurrency0Decimals':
      return str.toLocaleString('de', { style: 'currency', currency: secondaryCurrency || 'EUR', maximumFractionDigits: 0 });
    case 'fxCurrency':
      return str.toLocaleString('de', { style: 'currency', currency: secondaryCurrency || 'EUR', maximumFractionDigits: 2 });
    case 'number0Decimals':
      return str.toLocaleString('de', { maximumFractionDigits: 0 });
    case 'number':
      return str.toLocaleString('de', { maximumFractionDigits: 2 });
    case 'percentage':
      return str.toLocaleString('de', { style: 'percent', maximumFractionDigits: 2 });
    case 'boolean':
      return str ? <CheckCircleIcon className="h-5 w-5 inline" /> : <XMarkIcon className="h-5 w-5 inline" />;
    default:
      return String(str);
  }
}

// generates all cells <td> of a row (may have between 1 and 4 values, depending on how tight the layout is)
export function BodyCell({ layoutColumnColsArray, rowData, rowIndex, colIndex, hidden, toggleDetailRow, openDetailRows, groupByColumn }) {
  const highlightThisColumn = layoutColumnColsArray.some((x) => (rowData.isSummaryRow || rowData.isTotalRow) && (x.highlight === true || (colIndex === 0 && x.highlight !== false)));

  return (
    <td
      id={`table-row-${rowIndex}-${layoutColumnColsArray[0].id}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...(highlightThisColumn && {
        // if any of the columns is to be highlighted
        onClick: () => {
          if (rowData.isSummaryRow) toggleDetailRow(rowData[groupByColumn]);
        },
      })}
      className={`w-full sm:w-auto sm:max-w-none ${hidden}
                 ${layoutColumnColsArray[0].textAlign ? layoutColumnColsArray[0].textAlign : 'text-right'}
                 ${rowData.isSummaryRow || rowData.isTotalRow ? 'font-bold' : ''}
                 ${highlightThisColumn ? 'cursor-pointer' : ''}
                 ${colIndex === 0 ? 'pl-1.5 sm:pl-3' : 'pl-1 sm:pl-2'} py-2 pr-1.5 xs:pr-3 sm:px-4`}
    >
      {/* insert all the values that belong in this column */}
      {layoutColumnColsArray
        // if the column uses isSummaryRowDisplay, filter the column definitions to be displayed in accordance with what the row type is
        .filter((columnDef) => !((rowData.isSummaryRow && columnDef.ifSummaryRowDisplay === false) || (!rowData.isSummaryRow && columnDef.ifSummaryRowDisplay === true)) || rowData.isTotalRow)
        .map((columnDef, indexItemWithinColumn) => (
          <>
            {/* if this is a first item of the first column (with highlight set to true or not set at all, to maintain backward compatibility)
            // or a highlighted column of a summary row, highlight values in blue and display button to toggle row detail below ( label / ellipsis are NOT supported here) */}
            {(rowData.isSummaryRow || rowData.isTotalRow) && (columnDef.highlight === true || (colIndex === 0 && indexItemWithinColumn === 0 && columnDef.highlight !== false)) ? (
              <div className="flex justify-start items-center pl-1 xs:pl-0" key={columnDef.id}>
                {!rowData.isTotalRow && <ButtonToggleRowDetail value={rowData[groupByColumn]} openDetailRows={openDetailRows} toggleDetailRow={toggleDetailRow} />}
                <span className={`${columnDef.classNamesBody || ''} text-brandBlue-500`}>
                  {formatString(rowData[columnDef.id], columnDef.type, rowData.accountCurrency || rowData.currency, rowData.fxCurrency)}
                </span>
              </div>
            ) : (
              <div
                /* handle cases other than first column of a summary row */
                key={columnDef.id}
                // eslint-disable-next-line max-len
                className={`${columnDef.classNamesBoth || ''} ${columnDef.classNamesBody || ''} ${rowData.isSummaryRow ? columnDef.ifSummaryRowclassNames : ''}`}
              >
                {/* check if displayElement has any displayInstead restrictions; otherwise display the actual cell value */}
                {!['label', 'ellipsis'].includes(columnDef.type)
                  && (!columnDef.displayElement?.displayInstead || (columnDef.displayElement?.displayInstead && !rowData[columnDef.displayElement?.displayInsteadWhenField]))
                  && formatString(rowData[columnDef.id], columnDef.type, rowData.assetCurrency || rowData.currency, rowData.fxCurrency)}

                {/* if displayElement is present, use it to conditionally display element displayElement.element when field displayElement.field is true */}
                {columnDef.displayElement && rowData[columnDef.displayElement.field] && (
                  <columnDef.displayElement.element id={columnDef.id} value={rowData[columnDef.displayElement.field]} rowData={rowData} />
                )}

                {/* if columnDef.type is label or vertical ellpsis, skip formatString and display the required component */}
                {columnDef.type === 'label' && <LabelButton transactionId={rowData.id} label={rowData.label} />}
                {columnDef.type === 'ellipsis' && <EllipsisButton transactionId={rowData.id} />}
              </div>
            )}
          </>
        ))}
    </td>
  );
}
BodyCell.propTypes = {
  layoutColumnColsArray: PropTypes.array.isRequired,
  rowData: PropTypes.object.isRequired,
  rowIndex: PropTypes.number.isRequired,
  colIndex: PropTypes.number.isRequired,
  hidden: PropTypes.string.isRequired,
  toggleDetailRow: PropTypes.func.isRequired,
  openDetailRows: PropTypes.array.isRequired,
  groupByColumn: PropTypes.string.isRequired,
};

// the <tr> element is rendered separately by TableVirtuoso in Table.jsx
export function TableRow({ index, rowObject, context: { tableLayout, hiddenColumns, toggleDetailRow, openDetailRows, groupByColumn } }) {
  TableRow.propTypes = {
    index: PropTypes.number.isRequired,
    rowObject: PropTypes.object.isRequired,
    context: PropTypes.object.isRequired,
  };

  return (
    <>
      {tableLayout.map((columnsArray, colIndex) => (
        // each BodyCell contains between one and usually three fields (div elements), depending on how small the page is
        <BodyCell
          key={`${index}-${colIndex}`}
          rowIndex={index}
          layoutColumnColsArray={columnsArray}
          rowData={rowObject}
          colIndex={colIndex}
          hidden={hiddenColumns.includes(columnsArray[0].id) ? 'hidden' : ''}
          toggleDetailRow={toggleDetailRow}
          openDetailRows={openDetailRows}
          groupByColumn={groupByColumn}
        />
      ))}
    </>
  );
}
