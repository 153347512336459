/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Auth, API } from 'aws-amplify';
import mockViewer from '../../sections/sharedReportsViewer/mockViewer';
import { zipFile, unzipFile } from '../../sections/sharedReportsViewer/handleZip';
import { globalAccountsView, globalQuotesView, inflowsCurrent, inflowsBaseline, outflowsCurrent, outflowsBaseline } from './data';
import getReportingSelector from '../../sections/reports/reportingSelectors';
import {
  completeAssetView,
  assetBalancesArray,
  currentNetWorth2,
  baselineNetWorth,
  currentAssetValueChange,
  baselineAssetValueChange,
  incomeFromCapitalCurrent,
  incomeFromCapitalBaseline,
} from './globalSelectors/overarching';
import { stocksMetadata } from './globalSelectors/stocks';

dayjs.extend(utc);

const initialState = {};

function convertBlobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      const conversionResult = reader.result;
      resolve(conversionResult.substr(conversionResult.indexOf(',') + 1));
    };
    reader.readAsDataURL(blob);
  });
}

export const getSharedReport = createAsyncThunk('viewer/getSharedReport', async ({ reportId, passcode }, { dispatch, getState }) => {
  const response = await axios.get(`https://monestry-shared-reports-${process.env.REACT_APP_ENV_SUFFIX}.s3.eu-central-1.amazonaws.com/${reportId}.zip`, { responseType: 'blob' });

  const file = response.data; // this file is of type Blob
  const base64EncodedEncryptedZip = await convertBlobToBase64(file);
  const unzippedObject = await unzipFile(base64EncodedEncryptedZip, passcode); // returns a js object which needs to go directly to state
  return unzippedObject; // extra reducer on the bottom takes the object and puts it in state
});

function getAccountBalance(state, accountId) {
  return assetBalancesArray(state)
    .filter((item) => item.accountId === accountId)
    .reduce((prev, curr) => prev + curr.current.valueBaseCurrency, 0);
}

export async function prepCallToApi(body) {
  const session = await Auth.currentSession();
  const myInit = {
    body,
    headers: {
      'Content-Type': 'application/json',
      Authorization: session.idToken.jwtToken,
    },
  };
  return myInit;
}

export const postSharedReport = createAsyncThunk('viewer/postSharedReport', async ({ passcode }, { dispatch, getState }) => {
  const state = getState();

  const selectCompleteAssetView = completeAssetView(state);
  const allStockAssetIds = Object.keys(stocksMetadata(state).displaySymbolByAssetId); // this is a dictionary, but its keys are all stock assetIds
  const allRealEstateAssetIds = Object.keys(selectCompleteAssetView.realEstate); // array of assetIds of all the real estate

  // for the stock positions report, we need all current quotes for each stock
  const selectGlobalQuotesView = globalQuotesView(state);
  const quotes = {};
  allStockAssetIds.forEach((assetId) => {
    quotes[assetId] = {};
    quotes[assetId].current = selectGlobalQuotesView[assetId]?.current;
  });

  // for the real estate value chart, we need all real estate assets quote objects
  allRealEstateAssetIds.forEach((assetId) => {
    quotes[assetId] = selectGlobalQuotesView[assetId];
  });

  // start constructing the report viewer object
  const reportViewerObject = {
    metadata: {
      publishedOn: dayjs.utc().valueOf(), // this is used in App to check if the app is running in the viewer mode
    },
    baseCurrency: state.user.profile.settings.baseCurrency, // kept for backward compatibility
    user: {
      profile: state.user.profile,
    },
    kpis: {
      // CAUTION: the fields on the viewer side do not match the selector names (because the selector names have been changed following UNISEL)
      currentNetWorth: currentNetWorth2(state),
      inflows: inflowsCurrent(state),
      inflowsReferencePeriod: inflowsBaseline(state),
      incomeFromCapital: incomeFromCapitalCurrent(state),
      incomeFromCapitalReferencePeriod: incomeFromCapitalBaseline(state),
      assetValueChange: currentAssetValueChange(state),
      assetValueChangeReferencePeriod: baselineAssetValueChange(state),
      outflows: outflowsCurrent(state),
      outflowsReferencePeriod: outflowsBaseline(state),
      baselineNetWorth: baselineNetWorth(state),
      baselineDate: state.user.profile.settings.baselineDate,
    },
    accounts: globalAccountsView(state).map((account) => ({
      id: account.id,
      category: account.category,
      name: account.name,
      currency: account.currency,
      balance: getAccountBalance(state, account.id),
    })),
    reportData: {
      snapshots: getReportingSelector(state, 'snapshots').data,
      balances: getReportingSelector(state, 'assets').data,
      completeAssetView: selectCompleteAssetView,
      quotes,
    },
  };
  // zip and send to customer/shareReport POST
  const zippedObject = await zipFile(reportViewerObject, passcode);
  const payload = await prepCallToApi(zippedObject);
  const response = await API.post('myAPI', 'customer/shareReport', payload);
  return response;
});

const viewerSlice = createSlice({
  name: 'viewer',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getSharedReport.fulfilled, (state, action) => {
      Object.assign(state, action.payload); // when doing state = action.payload state doesn't get mutated, but reassiged, so it is not noticed
    });
  },
  // INFO: there is another reducer in 'user' slice which updates the baseCurrency in state.user.profile.settings.baseCurrency, as many components depend on it
});

export default viewerSlice.reducer;
